h1 {
    font-size: 48px;
    line-height: 1
}

h1.fs-lg {
    font-size: 60px;
    line-height: 70px
}

h2 {
    font-size: 36px;
    line-height: 50px
}

h3 {
    font-size: 30px;
    line-height: 44px
}

h4 {
    font-size: 21px;
    line-height: 30px
}

h5 {
    font-size: 18px;
    line-height: 24px
}

h6 {
    font-size: 16px;
    line-height: 21px
}

a {
    text-decoration: none;
    font-weight: 400;
    outline: none !important;
    cursor: pointer;
    font-size: 17px;
    line-height: 35px;
    transition: all .4s ease-in-out
}

p {
    font-size: 17px;
    font-weight: 400;
    line-height: 33px;
    margin: 0
}

.coverbg {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover !important
}

.mx-auto {
    margin: 0 auto
}

.align-center {
    display: flex;
    justify-content: center;
    align-items: center
}

.align-center:before,
.align-center:after {
    content: none
}

.align-center-h {
    display: flex;
    justify-content: center
}

.align-center-h:before,
.align-center-h:after {
    content: none
}

.align-center-v {
    height: 100%;
    display: flex;
    align-items: center
}

.align-center-v:before,
.align-center-v:after {
    content: none
}

.align-center-v-b {
    height: 100%;
    display: flex;
    align-items: flex-end
}

.align-center-v-b:before,
.align-center-v-b:after {
    content: none
}

.justify-content-center {
    justify-content: center !important;
    display: flex;
    flex-wrap: wrap
}

.justify-content-center:before,
.justify-content-center:after {
    content: none
}

.eq-height {
    display: -moz-flexbox;
    display: -o-flexbox;
    display: flex;
    -o-flex-wrap: wrap;
    flex-wrap: wrap
}

.eq-height:before,
.eq-height:after {
    content: none
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white a {
    color: #fff !important
}

.mlm-30 {
    margin-left: -30px
}

.pl-5 {
    padding-left: 5px
}

.pl-10 {
    padding-left: 10px
}

.pl-15 {
    padding-left: 15px
}

.pl-20 {
    padding-left: 20px
}

.pl-25 {
    padding-left: 25px
}

.pl-30 {
    padding-left: 30px
}

.pl-35 {
    padding-left: 35px
}

.pl-40 {
    padding-left: 40px
}

.pl-45 {
    padding-left: 45px
}

.pl-50 {
    padding-left: 50px
}

.pl-60 {
    padding-left: 60px
}

.pl-65 {
    padding-left: 65px
}

.pl-70 {
    padding-left: 70px
}

.pl-75 {
    padding-left: 75px
}

.pl-80 {
    padding-left: 80px
}

.pl-90 {
    padding-left: 90px
}

.pl-100 {
    padding-left: 100px
}

.pl-110 {
    padding-left: 110px
}

.pl-115 {
    padding-left: 115px
}

.pl-120 {
    padding-left: 120px
}

.pl-125 {
    padding-left: 125px
}

.pl-130 {
    padding-left: 130px
}

.pl-140 {
    padding-left: 140px
}

.pl-150 {
    padding-left: 150px
}

.pr-5 {
    padding-right: 5px
}

.pr-10 {
    padding-right: 10px
}

.pr-15 {
    padding-right: 15px
}

.pr-20 {
    padding-right: 20px
}

.pr-25 {
    padding-right: 25px
}

.pr-30 {
    padding-right: 30px
}

.pr-35 {
    padding-right: 35px
}

.pr-40 {
    padding-right: 40px
}

.pr-45 {
    padding-right: 45px
}

.pr-50 {
    padding-right: 50px
}

.pr-60 {
    padding-right: 60px
}

.pr-70 {
    padding-right: 70px
}

.pr-75 {
    padding-right: 75px
}

.pr-80 {
    padding-right: 80px
}

.pr-90 {
    padding-right: 90px
}

.pr-100 {
    padding-right: 100px
}

.pr-110 {
    padding-right: 110px
}

.pr-115 {
    padding-right: 115px
}

.pr-120 {
    padding-right: 120px
}

.pr-125 {
    padding-right: 125px
}

.pr-130 {
    padding-right: 130px
}

.pr-140 {
    padding-right: 140px
}

.pr-150 {
    padding-right: 150px
}

.pt-5 {
    padding-top: 5px
}

.pt-10 {
    padding-top: 10px
}

.pt-15 {
    padding-top: 15px
}

.pt-20 {
    padding-top: 20px
}

.pt-25 {
    padding-top: 25px
}

.pt-30 {
    padding-top: 30px
}

.pt-35 {
    padding-top: 35px
}

.pt-40 {
    padding-top: 40px
}

.pt-45 {
    padding-top: 45px
}

.pt-50 {
    padding-top: 50px
}

.pt-60 {
    padding-top: 60px
}

.pt-65 {
    padding-top: 65px
}

.pt-70 {
    padding-top: 70px
}

.pt-75 {
    padding-top: 75px
}

.pt-80 {
    padding-top: 80px
}

.pt-90 {
    padding-top: 90px
}

.pt-100 {
    padding-top: 100px
}

.pt-110 {
    padding-top: 110px
}

.pt-115 {
    padding-top: 115px
}

.pt-120 {
    padding-top: 120px
}

.pt-125 {
    padding-top: 125px
}

.pt-130 {
    padding-top: 130px
}

.pt-140 {
    padding-top: 140px
}

.pt-150 {
    padding-top: 150px
}

.pb-5 {
    padding-bottom: 5px
}

.pb-10 {
    padding-bottom: 10px
}

.pb-15 {
    padding-bottom: 15px
}

.pb-20 {
    padding-bottom: 20px
}

.pb-25 {
    padding-bottom: 25px
}

.pb-30 {
    padding-bottom: 30px
}

.pb-35 {
    padding-bottom: 35px
}

.pb-40 {
    padding-bottom: 40px
}

.pb-45 {
    padding-bottom: 45px
}

.pb-50 {
    padding-bottom: 50px
}

.pb-60 {
    padding-bottom: 60px
}

.pb-65 {
    padding-bottom: 65px
}

.pb-70 {
    padding-bottom: 70px
}

.pb-75 {
    padding-bottom: 75px
}

.pb-80 {
    padding-bottom: 80px
}

.pb-90 {
    padding-bottom: 90px
}

.pb-100 {
    padding-bottom: 100px
}

.pb-110 {
    padding-bottom: 110px
}

.pb-115 {
    padding-bottom: 115px
}

.pb-120 {
    padding-bottom: 120px
}

.pb-125 {
    padding-bottom: 125px
}

.pb-130 {
    padding-bottom: 130px
}

.pb-140 {
    padding-bottom: 140px
}

.pb-150 {
    padding-bottom: 150px
}

.ml-5 {
    margin-left: 5px
}

.ml-10 {
    margin-left: 10px
}

.ml-15 {
    margin-left: 15px
}

.ml-20 {
    margin-left: 20px
}

.ml-25 {
    margin-left: 25px
}

.ml-30 {
    margin-left: 30px
}

.ml-35 {
    margin-left: 35px
}

.ml-40 {
    margin-left: 40px
}

.ml-45 {
    margin-left: 45px
}

.ml-50 {
    margin-left: 50px
}

.ml-60 {
    margin-left: 60px
}

.ml-70 {
    margin-left: 70px
}

.ml-75 {
    margin-left: 75px
}

.ml-80 {
    margin-left: 80px
}

.ml-90 {
    margin-left: 90px
}

.ml-100 {
    margin-left: 100px
}

.ml-110 {
    margin-left: 110px
}

.ml-115 {
    margin-left: 115px
}

.ml-120 {
    margin-left: 120px
}

.ml-125 {
    margin-left: 125px
}

.ml-130 {
    margin-left: 130px
}

.ml-140 {
    margin-left: 140px
}

.ml-150 {
    margin-left: 150px
}

.mr-5 {
    margin-right: 5px
}

.mr-10 {
    margin-right: 10px
}

.mr-15 {
    margin-right: 15px
}

.mr-20 {
    margin-right: 20px
}

.mr-25 {
    margin-right: 25px
}

.mr-30 {
    margin-right: 30px
}

.mr-35 {
    margin-right: 35px
}

.mr-40 {
    margin-right: 40px
}

.mr-45 {
    margin-right: 45px
}

.mr-50 {
    margin-right: 50px
}

.mr-60 {
    margin-right: 60px
}

.mr-70 {
    margin-right: 70px
}

.mr-75 {
    margin-right: 75px
}

.mr-80 {
    margin-right: 80px
}

.mr-90 {
    margin-right: 90px
}

.mr-100 {
    margin-right: 100px
}

.mr-110 {
    margin-right: 110px
}

.mr-115 {
    margin-right: 115px
}

.mr-120 {
    margin-right: 120px
}

.mr-125 {
    margin-right: 125px
}

.mr-130 {
    margin-right: 130px
}

.mr-140 {
    margin-right: 140px
}

.mr-150 {
    margin-right: 150px
}

.mt-5 {
    margin-top: 5px
}

.mt-10 {
    margin-top: 10px
}

.mt-15 {
    margin-top: 15px
}

.mt-20 {
    margin-top: 20px
}

.mt-25 {
    margin-top: 25px
}

.mt-30 {
    margin-top: 30px
}

.mt-35 {
    margin-top: 35px
}

.mt-40 {
    margin-top: 40px
}

.mt-45 {
    margin-top: 45px
}

.mt-50 {
    margin-top: 50px
}

.mt-60 {
    margin-top: 60px
}

.mt-70 {
    margin-top: 70px
}

.mt-75 {
    margin-top: 75px
}

.mt-80 {
    margin-top: 80px
}

.mt-90 {
    margin-top: 90px
}

.mt-100 {
    margin-top: 100px
}

.mt-110 {
    margin-top: 110px
}

.mt-115 {
    margin-top: 115px
}

.mt-120 {
    margin-top: 120px
}

.mt-125 {
    margin-top: 125px
}

.mt-130 {
    margin-top: 130px
}

.mt-140 {
    margin-top: 140px
}

.mt-150 {
    margin-top: 150px
}

.mb-5 {
    margin-bottom: 5px
}

.mb-10 {
    margin-bottom: 10px
}

.mb-15 {
    margin-bottom: 15px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-25 {
    margin-bottom: 25px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-35 {
    margin-bottom: 35px
}

.mb-40 {
    margin-bottom: 40px
}

.mb-45 {
    margin-bottom: 45px
}

.mb-50 {
    margin-bottom: 50px
}

.mb-55 {
    margin-bottom: 55px
}

.mb-60 {
    margin-bottom: 60px
}

.mb-70 {
    margin-bottom: 70px
}

.mb-75 {
    margin-bottom: 75px
}

.mb-80 {
    margin-bottom: 80px
}

.mb-90 {
    margin-bottom: 90px
}

.mb-100 {
    margin-bottom: 100px
}

.mb-110 {
    margin-bottom: 110px
}

.mb-115 {
    margin-bottom: 115px
}

.mb-120 {
    margin-bottom: 120px
}

.mb-125 {
    margin-bottom: 125px
}

.mb-130 {
    margin-bottom: 130px
}

.mb-140 {
    margin-bottom: 140px
}

.mb-150 {
    margin-bottom: 150px
}

.mmb10 {
    margin-bottom: -10px
}

.bg-cover {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center
}

.bg-center {
    background-position: center
}

.bg-contain {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative
}

.bg-right {
    background-repeat: no-repeat;
    background-position: right
}

.bg-left {
    background-repeat: no-repeat;
    background-position: left
}

.bg-bottom {
    background-repeat: no-repeat;
    background-position: bottom
}

.bg-top {
    background-repeat: no-repeat;
    background-position: top
}

.bg-100 {
    background-size: 100% 100%
}

.lr-9 {
    margin-left: -9px;
    margin-right: -9px
}

.lr-9 [class*=col] {
    padding-left: 9px;
    padding-right: 9px
}

section {
    position: relative;
    overflow: hidden;
    z-index: 1
}

.shape {
    position: absolute
}

@media(max-width:1191px) {
    .shape {
        display: none;
        opacity: 0
    }
}

.navbar-brand {
    padding: 0;
    margin-right: 0
}

#scrollUp {
    background-color: #121212;
    border-radius: 50%;
    bottom: 20px;
    color: #fff;
    font-size: 22px;
    height: 40px;
    line-height: 44px;
    right: 20px;
    text-align: center;
    width: 40px;
    box-shadow: 0 5px 15px 0 rgba(1, 29, 80, .1);
    font-weight: 400;
    transition: all .4s ease-in-out
}

#scrollUp:hover {
    background-color: #f4b930
}

@media only screen and (max-width:991px) {
    #scrollUp {
        font-size: 15px;
        height: 30px;
        line-height: 32px;
        right: 30px;
        width: 30px
    }
}

@keyframes playVideo {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, .3)
    }
    40% {
        box-shadow: 0 0 0 20px transparent
    }
    80% {
        box-shadow: 0 0 0 20px transparent
    }
    100% {
        box-shadow: 0 0 0 0 transparent
    }
}

@keyframes dimond {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0)
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}

@keyframes movebounce {
    0% {
        transform: translateY(0px)
    }
    50% {
        transform: translateY(20px)
    }
    100% {
        transform: translateY(0px)
    }
}

@keyframes bounceUp {
    0% {
        transform: translateY(0px)
    }
    50% {
        transform: translateY(40px)
    }
    100% {
        transform: translateY(0px)
    }
}

@keyframes moveleftbounce {
    0% {
        transform: translateX(0px)
    }
    50% {
        transform: translateX(40px)
    }
    100% {
        transform: translateX(0px)
    }
}

@keyframes moverightbounce {
    0% {
        margin-left: 0
    }
    50% {
        margin-left: 30px
    }
    100% {
        margin-left: 0
    }
}

.rotateme {
    animation-name: rotateme;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

@keyframes guraguri {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
}

@keyframes rotate3d {
    0% {
        transform: rotateY(0deg)
    }
    100% {
        transform: rotateY(360deg)
    }
}

@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(.55)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

.about-shape-wrap .shape1 {
    animation: moveleftbounce 4s infinite linear
}

.about-shape-wrap .shape2 {
    animation: movebounce 4s infinite linear
}

.about-shape-wrap .shape3 {
    animation: rotate3d 4s infinite linear
}

.about-shape-wrap .shape4 {
    animation: bounce 4s infinite linear
}

.about-shape-wrap .shape5 {
    animation: movebounce 4s infinite linear
}

.about-shape-wrap .shape6 {
    animation: movebounce 4s infinite linear
}

.about-shape-wrap .shape7 {
    animation: bounce 4s infinite linear
}

.service-shape-wrap .s2 {
    animation: moveleftbounce 4s infinite linear
}

.service-shape-wrap .s3 {
    animation: rotate3d 4s infinite linear
}

.service-shape-wrap .s4 {
    animation: bounce 4s infinite linear
}

.service-shape-wrap .s5 {
    animation: movebounce 4s infinite linear
}

.service-shape-wrap .s6 {
    animation: bounce 4s infinite linear
}

.service-shape-wrap .s7 {
    animation: bounce 4s infinite linear
}

.call-to-action-shape-wrap .cta1 {
    animation: moveleftbounce 4s infinite linear
}

.call-to-action-shape-wrap .cta2 {
    animation: moveleftbounce 4s infinite linear
}

.call-to-action-shape-wrap .cta3 {
    animation: bounce 4s infinite linear
}

.call-to-action-shape-wrap .cta4 {
    animation: bounce 6s infinite linear
}

.call-to-action-shape-wrap .cta5 {
    animation: movebounce 4s infinite linear
}

.call-to-action-shape-wrap .cta6 {
    animation: bounce 4s infinite linear
}

.call-to-action-shape-wrap .cta7 {
    animation: rotate3d 5s infinite linear
}

.faq-contact-shape .faqs1 {
    animation: movebounce 4s infinite linear
}

.faq-contact-shape .faqs2 {
    animation: bounce 5s infinite linear
}

.faq-contact-shape .faqs3 {
    animation: bounce 5s infinite linear
}

.faq-contact-shape .faqs4 {
    animation: bounce 8s infinite linear
}

.hero-animate .animate1 {
    animation: bounceUp 5s linear infinite
}

.hero-animate .animate2 {
    animation: bounceUp 4s linear infinite
}

.hero-animate .animate3 {
    animation: bounceUp 7s linear infinite
}

.theme-btn {
    border-radius: 5px;
    background: #2e77ff;
    font-size: 17px;
    line-height: 35px;
    text-transform: capitalize;
    display: inline-block;
    padding: 14px 40px;
    margin-top: 30px;
    border: 1px solid transparent;
    box-sizing: border-box;
    transition: all .4s ease-in-out;
    color: #fff
}

@media(max-width:767px) {
    .theme-btn {
        padding: 10px 30px;
        font-size: 15px
    }
}

.theme-btn.no-fill {
    background: 0 0;
    border-color: #e1d4d4;
    color: #232328;
    margin-left: 13px
}

.theme-btn.no-fill:hover {
    background: #f4b930;
    color: #fff;
    border-color: #f4b930
}

@media(max-width:767px) {
    .theme-btn.no-fill {
        margin-left: 0
    }
}

.theme-btn:hover {
    background: #f4b930;
    color: #fff;
    border: 1px solid #f4b930
}

.cta-btn-2 {
    font-size: 19px;
    line-height: 35px;
    margin-top: 59px;
    display: inline-block;
    transition: .35s;
    text-decoration: underline
}

@media(max-width:767px) {
    .cta-btn-2 {
        margin-top: 30px;
        font-size: 16px
    }
}

.cta-btn-2 i {
    margin-left: 30px
}

.home-three .theme-btn {
    border-radius: 32.5px
}

.theme-btn.round-btn {
    border-radius: 32.5px
}

.home-two .theme-btn,
.home-2 .theme-btn,
.theme-2 .theme-btn,
.theme-two .theme-btn {
    background: #ff8929
}

.home-two .theme-btn:hover,
.home-2 .theme-btn:hover,
.theme-2 .theme-btn:hover,
.theme-two .theme-btn:hover {
    background: #000;
    color: #fff;
    border-color: #000
}

.home-two .theme-btn.no-fill,
.home-2 .theme-btn.no-fill,
.theme-2 .theme-btn.no-fill,
.theme-two .theme-btn.no-fill {
    background: 0 0;
    border-color: #e1d4d4;
    color: #232328;
    margin-left: 13px
}

.home-two .theme-btn.no-fill:hover,
.home-2 .theme-btn.no-fill:hover,
.theme-2 .theme-btn.no-fill:hover,
.theme-two .theme-btn.no-fill:hover {
    background: #000;
    color: #fff;
    border-color: #000
}

.home-two .about-wrap .about-content h2 span,
.home-2 .about-wrap .about-content h2 span,
.theme-2 .about-wrap .about-content h2 span,
.theme-two .about-wrap .about-content h2 span {
    color: #ff8929
}

.home-two .about-wrap .about-content h2 span::before,
.home-2 .about-wrap .about-content h2 span::before,
.theme-2 .about-wrap .about-content h2 span::before,
.theme-two .about-wrap .about-content h2 span::before {
    background: #ff8929
}

.home-two .services-wrap .service-head-content .tag-title,
.home-2 .services-wrap .service-head-content .tag-title,
.theme-2 .services-wrap .service-head-content .tag-title,
.theme-two .services-wrap .service-head-content .tag-title {
    color: #ff8929;
    background: #fff0ef
}

.home-two .services-wrap .service-head-content .tag-title:hover,
.home-2 .services-wrap .service-head-content .tag-title:hover,
.theme-2 .services-wrap .service-head-content .tag-title:hover,
.theme-two .services-wrap .service-head-content .tag-title:hover {
    background: #ff8929;
    color: #fff
}

.home-two .case-studies-wrap .case-study-carousel-wrap .single-case-study::before,
.home-2 .case-studies-wrap .case-study-carousel-wrap .single-case-study::before,
.theme-2 .case-studies-wrap .case-study-carousel-wrap .single-case-study::before,
.theme-two .case-studies-wrap .case-study-carousel-wrap .single-case-study::before {
    background: #ff8929
}

.home-two .case-studies-wrap .case-study-carousel-wrap .slick-dots li.slick-active button,
.home-2 .case-studies-wrap .case-study-carousel-wrap .slick-dots li.slick-active button,
.theme-2 .case-studies-wrap .case-study-carousel-wrap .slick-dots li.slick-active button,
.theme-two .case-studies-wrap .case-study-carousel-wrap .slick-dots li.slick-active button {
    background: #ff8929
}

.home-two .testimonial-wrap .testimonial-items-list .slick-arrow:hover,
.home-2 .testimonial-wrap .testimonial-items-list .slick-arrow:hover,
.theme-2 .testimonial-wrap .testimonial-items-list .slick-arrow:hover,
.theme-two .testimonial-wrap .testimonial-items-list .slick-arrow:hover {
    background: #ff8929;
    color: #fff;
    border-color: #ff8929
}

.home-two .call-to-action-wrap,
.home-2 .call-to-action-wrap,
.theme-2 .call-to-action-wrap,
.theme-two .call-to-action-wrap {
    background-image: -ms-linear-gradient(165deg, #6848ba 0%, #d54e8b 100%)
}

.home-two .cta-btn-2,
.home-2 .cta-btn-2,
.theme-2 .cta-btn-2,
.theme-two .cta-btn-2 {
    color: #ff8929
}

.home-two .cta-btn-2:hover,
.home-2 .cta-btn-2:hover,
.theme-2 .cta-btn-2:hover,
.theme-two .cta-btn-2:hover {
    color: #000
}

.home-two .faq-wrap-contact-us,
.home-2 .faq-wrap-contact-us,
.theme-2 .faq-wrap-contact-us,
.theme-two .faq-wrap-contact-us {
    background-image: -ms-linear-gradient(165deg, #6848ba 0%, #d54e8b 100%)
}

.home-two .contact-us .contact-form form .single-input input,
.home-two .contact-us .contact-form form .single-input textarea,
.home-2 .contact-us .contact-form form .single-input input,
.home-2 .contact-us .contact-form form .single-input textarea,
.theme-2 .contact-us .contact-form form .single-input input,
.theme-2 .contact-us .contact-form form .single-input textarea,
.theme-two .contact-us .contact-form form .single-input input,
.theme-two .contact-us .contact-form form .single-input textarea {
    background: #ff8929;
    border-color: #ff8929
}

.home-two .footer-wrap .social-profile-links a,
.home-2 .footer-wrap .social-profile-links a,
.theme-2 .footer-wrap .social-profile-links a,
.theme-two .footer-wrap .social-profile-links a {
    border-color: #ff8929;
    color: #ff8929
}

.home-two .footer-wrap .social-profile-links a:hover,
.home-2 .footer-wrap .social-profile-links a:hover,
.theme-2 .footer-wrap .social-profile-links a:hover,
.theme-two .footer-wrap .social-profile-links a:hover {
    background: #ff8929
}

.home-two .contact-us .contact-form form .single-input input,
.home-two .contact-us .contact-form form .single-input textarea,
.home-2 .contact-us .contact-form form .single-input input,
.home-2 .contact-us .contact-form form .single-input textarea,
.theme-2 .contact-us .contact-form form .single-input input,
.theme-2 .contact-us .contact-form form .single-input textarea,
.theme-two .contact-us .contact-form form .single-input input,
.theme-two .contact-us .contact-form form .single-input textarea {
    color: #fff !important
}

.home-two .contact-us .contact-form form .single-input input::placeholder,
.home-two .contact-us .contact-form form .single-input textarea::placeholder,
.home-2 .contact-us .contact-form form .single-input input::placeholder,
.home-2 .contact-us .contact-form form .single-input textarea::placeholder,
.theme-2 .contact-us .contact-form form .single-input input::placeholder,
.theme-2 .contact-us .contact-form form .single-input textarea::placeholder,
.theme-two .contact-us .contact-form form .single-input input::placeholder,
.theme-two .contact-us .contact-form form .single-input textarea::placeholder {
    color: #fff !important
}

.home-two .contact-us .contact-form form .submit-btn,
.home-2 .contact-us .contact-form form .submit-btn,
.theme-2 .contact-us .contact-form form .submit-btn,
.theme-two .contact-us .contact-form form .submit-btn {
    background-color: #cddbfd !important
}

.home-two .contact-us .contact-form form .form-btn span,
.home-2 .contact-us .contact-form form .form-btn span,
.theme-2 .contact-us .contact-form form .form-btn span,
.theme-two .contact-us .contact-form form .form-btn span {
    color: #e4d4e6
}

.home-two .faq-accordion .accordion .card-header a,
.home-2 .faq-accordion .accordion .card-header a,
.theme-2 .faq-accordion .accordion .card-header a,
.theme-two .faq-accordion .accordion .card-header a {
    color: #ff8929
}

.content-area-bg {
    background-image: -ms-linear-gradient(90deg, #f7f9fe 0%, white 100%)
}

.pre-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999999999;
    text-align: center;
    background: #ffffff;
    display: block;
    overflow: hidden
}

.pre-loader .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px
}

header {
    top: 30px;
    width: 100%;
    z-index: 9999;
    position: absolute;
    transition: all .6s ease-in-out
}

header .main-menu .menu-style-one ul li {
    list-style: none;
    display: inline-block
}

header .main-menu .menu-style-one ul li:last-child a {
    margin-right: 0
}

header .main-menu .menu-style-one ul li a {
    display: inline-block;
    color: #fff;
    margin-right: 35px;
    text-transform: capitalize;
    line-height: 24px;
    padding: 15px 0;
    transition: all .3s ease-in-out
}

header .main-menu .menu-style-one ul li a:hover {
    color: #f4b930
}

@media(max-width:1199px) {
    header .main-menu .menu-style-one ul li a {
        margin-right: 25px
    }
}

header .main-menu .menu-style-one ul li .submenu {
    left: 0;
    top: 100%;
    opacity: 0;
    margin: 0;
    padding: 20px 0;
    min-width: 190px;
    position: absolute;
    background: #fff;
    visibility: hidden;
    transition: all .5s ease-in-out;
    transform: translateY(30px);
    box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, .05)
}

header .main-menu .menu-style-one ul li .submenu li {
    display: block;
    padding: 0
}

header .main-menu .menu-style-one ul li .submenu li a {
    margin: 0;
    color: #000c80;
    display: block;
    padding: 5px 25px;
    text-align: left;
    transition: all .3s ease-in-out
}

header .main-menu .menu-style-one ul li .submenu li a:hover {
    color: #f4b930
}

header .main-menu .menu-style-one ul li:hover .submenu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    display: block
}

header .main-menu .get-started-btn a {
    display: inline-block;
    line-height: 24px;
    background: #2e77ff;
    color: #fff;
    padding: 16px 33px;
    border-radius: 5px
}

header .main-menu .get-started-btn a:hover {
    background: #f4b930;
    color: #fff
}

@media(max-width:1299px) {
    header .main-menu .get-started-btn a {
        line-height: 22px;
        padding: 14px 23px
    }
}

@media(max-width:1199px) {
    header .main-menu .get-started-btn a {
        line-height: 22px;
        padding: 12px 25px;
        font-size: 14px
    }
}

header .main-menu .get-started-btn.no-fill a {
    color: #232328;
    border: 1px solid #232328;
    background: 0 0
}

header .main-menu .get-started-btn.no-fill a:hover {
    background: #ff8929;
    color: #fff;
    border-color: #ff8929
}

@media(max-width:1050px) {
    header .main-menu .get-started-btn.no-fill a {
        padding: 12px 21px
    }
}

header .main-menu .get-started-btn.no-fill.white a {
    color: #fff;
    border: 1px solid #fff;
    background: 0 0
}

header .main-menu .get-started-btn.no-fill.white a:hover {
    background: #f4b930;
    color: #fff;
    border-color: #f4b930
}

@media(max-width:1050px) {
    header .main-menu .get-started-btn.no-fill.white a {
        padding: 12px 21px
    }
}

header .main-menu .get-started-btn.get-round-btn a {
    color: #fff;
    background: #7f99ab;
    display: inline-block;
    border-radius: 27.5px
}

header .main-menu .get-started-btn.get-round-btn a:hover {
    background: #2e77ff;
    color: #fff;
    border-color: #2e77ff
}

@media(max-width:1050px) {
    header .main-menu .get-started-btn.get-round-btn a {
        padding: 12px 21px
    }
}

header .main-menu .menu-style-two {
    margin-right: -80px;
    z-index: 1;
    position: relative
}

@media(max-width:1800px) {
    header .main-menu .menu-style-two {
        margin-right: 0
    }
}

header .main-menu .menu-style-two ul li {
    list-style: none;
    display: inline-block
}

header .main-menu .menu-style-two ul li:last-child a {
    margin-right: 0
}

header .main-menu .menu-style-two ul li a {
    display: inline-block;
    color: #000;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
    padding: 0px 13px;
    background: white;
}

header .main-menu .menu-style-two ul li a:hover {
    color: #ff8929
}

@media(max-width:1299px) {
    header .main-menu .menu-style-two ul li a {
        margin-right: 25px;
        font-size: 14px
    }
}

@media(max-width:1450px) {
    header .main-menu .menu-style-two ul li a {
        /* margin-right: 30px */
    }
}

header .main-menu .menu-style-two ul li .submenu {
    top: 100%;
    opacity: 0;
    margin: 0;
    padding: 20px 0;
    min-width: 195px;
    position: absolute;
    background: #fff;
    visibility: hidden;
    transition: all .5s ease-in-out;
    transform: translateY(30px);
    box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, .05)
}

header .main-menu .menu-style-two ul li .submenu li {
    display: block;
    padding: 0
}

header .main-menu .menu-style-two ul li .submenu li a {
    margin: 0;
    display: block;
    text-align: left;
    padding: 5px 25px;
    color: #ff8929;
    transition: all .3s ease-in-out
}

header .main-menu .menu-style-two ul li .submenu li a:hover {
    color: #000
}

header .main-menu .menu-style-two ul li:hover .submenu {
    opacity: 1;
    display: block;
    visibility: visible;
    transform: translateY(0px)
}

header .main-menu .menu-style-three ul {
    padding-right: 25px
}

header .main-menu .menu-style-three ul li {
    list-style: none;
    display: inline-block
}

header .main-menu .menu-style-three ul li:last-child a {
    margin-right: 0
}

header .main-menu .menu-style-three ul li a {
    color: #fff;
    font-size: 15px;
    line-height: 24px;
    padding: 22px 0;
    margin-right: 43px;
    display: inline-block;
    transition: all .3s ease-in-out;
    text-transform: uppercase
}

header .main-menu .menu-style-three ul li a:hover {
    color: #f4b930
}

@media(max-width:1450px) {
    header .main-menu .menu-style-three ul li a {
        margin-right: 30px
    }
}

@media(max-width:1299px) {
    header .main-menu .menu-style-three ul li a {
        font-size: 14px;
        margin-right: 25px
    }
}

header .main-menu .menu-style-three ul li .submenu {
    top: 100%;
    opacity: 0;
    left: 0;
    margin: 0;
    min-width: 195px;
    padding: 20px 0;
    visibility: hidden;
    position: absolute;
    background: #fff;
    transition: all .5s ease-in-out;
    transform: translateY(30px);
    box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, .05)
}

header .main-menu .menu-style-three ul li .submenu li {
    padding: 0;
    display: block
}

header .main-menu .menu-style-three ul li .submenu li a {
    margin: 0;
    display: block;
    text-align: left;
    padding: 5px 25px;
    color: #2e77ff;
    transition: all .3s ease-in-out
}

header .main-menu .menu-style-three ul li .submenu li a:hover {
    color: #f4b930
}

header .main-menu .menu-style-three ul li:hover .submenu {
    opacity: 1;
    display: block;
    visibility: visible;
    transform: translateY(0px)
}

header .main-menu .menu-style-four ul {
    padding-right: 25px
}

header .main-menu .menu-style-four ul li {
    list-style: none;
    display: inline-block
}

header .main-menu .menu-style-four ul li:last-child a {
    margin-right: 0
}

header .main-menu .menu-style-four ul li a {
    color: #232328;
    font-size: 15px;
    line-height: 24px;
    padding: 22px 0;
    margin-right: 43px;
    display: inline-block;
    transition: all .3s ease-in-out;
    text-transform: uppercase
}

header .main-menu .menu-style-four ul li a:hover {
    color: #2e77ff
}

@media(max-width:1450px) {
    header .main-menu .menu-style-four ul li a {
        margin-right: 30px
    }
}

@media(max-width:1299px) {
    header .main-menu .menu-style-four ul li a {
        font-size: 14px;
        margin-right: 25px
    }
}

header .main-menu .menu-style-four ul li .submenu {
    top: 100%;
    opacity: 0;
    left: 0;
    margin: 0;
    min-width: 195px;
    padding: 20px 0;
    visibility: hidden;
    position: absolute;
    background: #fff;
    transition: all .5s ease-in-out;
    transform: translateY(30px);
    box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, .05)
}

header .main-menu .menu-style-four ul li .submenu li {
    padding: 0;
    display: block
}

header .main-menu .menu-style-four ul li .submenu li a {
    margin: 0;
    display: block;
    text-align: left;
    padding: 5px 25px;
    color: #2e77ff;
    transition: all .3s ease-in-out
}

header .main-menu .menu-style-four ul li .submenu li a:hover {
    color: #f4b930
}

header .main-menu .menu-style-four ul li:hover .submenu {
    opacity: 1;
    display: block;
    visibility: visible;
    transform: translateY(0px)
}

.sticky {
    top: 0;
    width: 100%;
    position: fixed;
    padding: 15px 0;
    background: #000b7f;
    animation: sticky .8s ease-in-out;
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, .15)
}

.sticky .mean-container .mean-bar {
    top: -7px;
    min-height: 0
}

@media(max-width:767px) {
    .sticky {
        position: relative
    }
}

.header-two.sticky {
    padding: 10px 0;
    background: #ff8929
}

.header-two.sticky .menu-style-two ul li a {
    color: #fff
}

.header-two.sticky .menu-style-two ul li a:hover {
    color: #000
}

.header-two.sticky .get-started-btn.no-fill a {
    color: #fff !important;
    border-color: #fff !important
}

.header-two.sticky .get-started-btn.no-fill a:hover {
    background: #000;
    color: #fff !important;
    border-color: #000 !important
}

.header-four.sticky .logo img {
    filter: brightness(111)
}

.header-four.sticky .menu-style-four ul li a {
    color: #fff
}

.header-four.sticky .menu-style-four ul li a:hover {
    color: #f4b930
}

.header-five .mean-container a.meanmenu-reveal {
    color: #04004d;
    border-color: #04004d
}

.header-five .mean-container a.meanmenu-reveal span {
    background: #04004d
}

.header-five .mean-container .mean-nav {
    background: #04004d
}

.header-five.sticky .logo img {
    filter: brightness(111)
}

.social-icons a {
    width: 37px;
    height: 37px;
    color: #747474;
    transition: .3s;
    line-height: 37px;
    margin-right: 5px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    background-color: #fff;
    box-shadow: 0 9px 21px 0 rgba(0, 0, 0, .05)
}

.social-icons a:hover {
    color: #fff;
    background-color: #785fff
}

@keyframes sticky {
    0% {
        opacity: 0;
        margin-top: -120px
    }
    50% {
        opacity: 0;
        margin-top: -60px
    }
    100% {
        opacity: 1;
        margin-top: 0
    }
}

.header-five.sticky {
    background-color: #04004d
}

.header-five.sticky .menu-style-two ul li a {
    color: #fff
}

.hero-one {
    position: relative
}

.hero-one .single-hero-slide {
    overflow: hidden;
    min-height: 800px;
    align-items: center;
    display: flex;
    position: relative;
    z-index: 1;
    background-image: linear-gradient(120deg, #189bfb 0%, #000b7f 100%)
}

@media(max-width:991px) {
    .hero-one .single-hero-slide {
        min-height: 700px
    }
}

@media(max-width:400px) {
    .hero-one .single-hero-slide {
        min-height: 800px
    }
}

.hero-one .single-hero-slide .hero-slide-content h1 {
    margin-bottom: 25px;
    margin-top: -5px
}

@media(max-width:1200px) {
    .hero-one .single-hero-slide .hero-slide-content h1 {
        line-height: 1.3
    }
}

@media(max-width:991px) {
    .hero-one .single-hero-slide .hero-slide-content h1 {
        font-size: 40px
    }
}

@media(max-width:767px) {
    .hero-one .single-hero-slide .hero-slide-content h1 {
        font-size: 34px
    }
}

.hero-one .single-hero-slide .hero-slide-content p {
    font-size: 19px;
    line-height: 35px;
    color: #cddbfd !important;
    padding-right: 10px
}

@media(max-width:991px) {
    .hero-one .single-hero-slide .hero-slide-content p {
        font-size: 17px
    }
}

.hero-one .single-hero-slide .hero-slide-content a {
    border-radius: 5px;
    background: #f4b930;
    font-size: 19px;
    line-height: 35px;
    text-transform: capitalize;
    display: inline-block;
    padding: 14px 44.5px;
    margin-top: 38px;
    border: 1px solid transparent;
    box-sizing: border-box;
    transition: all .4s ease-in-out
}

@media(max-width:991px) {
    .hero-one .single-hero-slide .hero-slide-content a {
        font-size: 16px;
        padding: 10px 30.5px
    }
}

@media(max-width:767px) {
    .hero-one .single-hero-slide .hero-slide-content a {
        font-size: 15px;
        padding: 9px 25px
    }
}

.hero-one .single-hero-slide .hero-slide-content a.no-fill {
    margin-left: 13px;
    background: 0 0;
    border-color: #fff
}

@media(max-width:480px) {
    .hero-one .single-hero-slide .hero-slide-content a.no-fill {
        margin-left: 0
    }
}

.hero-one .single-hero-slide .hero-slide-content a.no-fill:hover {
    background: #f4b930;
    color: #fff;
    border-color: #f4b930
}

.hero-one .single-hero-slide .hero-slide-content a:hover {
    background: #2e77ff;
    color: #fff
}

@media(max-width:991px) {
    .hero-one .single-hero-slide .hero-slide-content.mlm-30 {
        margin-left: 0
    }
}

.hero-one .single-hero-slide .slide-illustration-img {
    position: absolute;
    bottom: 0;
    height: auto;
    left: 0;
    z-index: -1;
    width: 40%;
    max-height: 690px
}

@media(max-width:991px) {
    .hero-one .single-hero-slide .slide-illustration-img {
        display: none
    }
}

.hero-one .single-hero-slide .slide-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: .2;
    background-repeat: no-repeat;
    background-position: right;
    mix-blend-mode: multiply
}

.hero-two {
    position: relative
}

.hero-two .single-hero-slide {
    overflow: hidden;
    min-height: 900px;
    align-items: center;
    display: flex;
    position: relative;
    z-index: 1;
    background-color: #fff9f8
}

.hero-two .single-hero-slide .hero-slide-content h1 {
    margin-bottom: 25px;
    line-height: 60px
}

@media(max-width:1200px) {
    .hero-two .single-hero-slide .hero-slide-content h1 {
        line-height: 1.3
    }
}

@media(max-width:991px) {
    .hero-two .single-hero-slide .hero-slide-content h1 {
        color: #fff;
        font-size: 40px
    }
}

@media(max-width:767px) {
    .hero-two .single-hero-slide .hero-slide-content h1 {
        color: #fff;
        font-size: 36px
    }
}

.hero-two .single-hero-slide .hero-slide-content p {
    font-size: 19px;
    line-height: 35px;
    color: #4f4e52;
    padding-right: 10px
}

@media(max-width:991px) {
    .hero-two .single-hero-slide .hero-slide-content p {
        color: #fff
    }
}

@media(max-width:767px) {
    .hero-two .single-hero-slide .hero-slide-content p {
        font-size: 17px
    }
}

.hero-two .single-hero-slide .hero-slide-content a.slide-btn {
    border-radius: 5px;
    background: #ff8929;
    font-size: 19px;
    line-height: 35px;
    text-transform: capitalize;
    display: inline-block;
    padding: 14px 44.5px;
    margin-top: 38px;
    border: 1px solid transparent;
    box-sizing: border-box;
    transition: all .4s ease-in-out;
    margin-right: 25px;
    color: #fff
}

.hero-two .single-hero-slide .hero-slide-content a.slide-btn:hover {
    background: #000;
    color: #fff
}

@media(max-width:991px) {
    .hero-two .single-hero-slide .hero-slide-content a.slide-btn {
        background: #2d1e1df2;
        font-size: 17px;
        padding: 10px 30.5px
    }
}

.hero-two .single-hero-slide .hero-slide-content .slide-video-btn {
    color: #232328
}

.hero-two .single-hero-slide .hero-slide-content .slide-video-btn i {
    line-height: 26px;
    font-size: 26px;
    margin-right: 10px
}

.hero-two .single-hero-slide .hero-slide-content .slide-video-btn:hover {
    color: #ff8929
}

@media(max-width:767px) {
    .hero-two .single-hero-slide .hero-slide-content .slide-video-btn {
        display: block;
        margin-top: 30px;
        font-size: 17px
    }
}

.hero-two .single-hero-slide .slide-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 39.31%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover
}

@media(max-width:1200px) {
    .hero-two .single-hero-slide .slide-bg {
        width: 32%
    }
}

@media(max-width:991px) {
    .hero-two .single-hero-slide .slide-bg {
        width: 100%
    }
    .hero-two .single-hero-slide .slide-bg::before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
        z-index: 1;
        background: #ff8929;
        opacity: .9
    }
}

@media(max-width:991px) {
    .hero-two .single-hero-slide {
        min-height: 800px
    }
}

.hero-slider-three {
    position: relative
}

.hero-slider-three .single-hero-slide {
    z-index: 1;
    overflow: hidden;
    position: relative;
    padding-top: 274px;
    padding-bottom: 380px;
    min-height: 1020px;
    background-image: linear-gradient(165deg, #189bfb 0%, #000b7f 100%)
}

@media(max-width:1200px) {
    .hero-slider-three .single-hero-slide {
        padding-top: 200px
    }
}

.hero-slider-three .single-hero-slide .slide-content h1 {
    color: #fff;
    margin-top: -12px;
    line-height: 60px;
    padding-right: 70px;
    margin-bottom: 24px
}

@media(max-width:1200px) {
    .hero-slider-three .single-hero-slide .slide-content h1 {
        line-height: 1.3;
        font-size: 40px
    }
}

@media(max-width:991px) {
    .hero-slider-three .single-hero-slide .slide-content h1 {
        font-size: 40px
    }
}

@media(max-width:767px) {
    .hero-slider-three .single-hero-slide .slide-content h1 {
        font-size: 36px
    }
}

@media(max-width:480px) {
    .hero-slider-three .single-hero-slide .slide-content h1 {
        font-size: 32px
    }
}

.hero-slider-three .single-hero-slide .slide-content p {
    font-size: 19px;
    line-height: 35px;
    color: #dee1ef;
    padding-right: 10px
}

@media(max-width:767px) {
    .hero-slider-three .single-hero-slide .slide-content p {
        font-size: 17px
    }
}

.hero-slider-three .single-hero-slide .slide-content .slide-round-btn {
    color: #fff;
    font-size: 19px;
    margin-top: 48px;
    line-height: 35px;
    margin-right: 25px;
    border-radius: 50px;
    padding: 14px 44.5px;
    display: inline-block;
    box-sizing: border-box;
    transition: all .4s ease-in-out;
    background: #f4b930;
    text-transform: capitalize;
    border: 1px solid transparent
}

.hero-slider-three .single-hero-slide .slide-content .slide-round-btn i {
    margin-left: 20px
}

.hero-slider-three .single-hero-slide .slide-content .slide-round-btn:hover {
    color: #fff;
    background: #2e77ff
}

@media(max-width:991px) {
    .hero-slider-three .single-hero-slide .slide-content .slide-round-btn {
        margin-top: 28px;
        padding: 10px 32px
    }
}

.hero-slider-three .single-hero-slide .slide-content .slide-video-btn {
    color: #fff
}

.hero-slider-three .single-hero-slide .slide-content .slide-video-btn i {
    font-size: 26px;
    line-height: 26px;
    margin-right: 10px
}

.hero-slider-three .single-hero-slide .slide-content .slide-video-btn:hover {
    color: #f4b930
}

@media(max-width:767px) {
    .hero-slider-three .single-hero-slide .slide-content .slide-video-btn {
        display: block;
        font-size: 17px;
        margin-top: 25px
    }
}

@media(max-width:991px) {
    .hero-slider-three .single-hero-slide {
        min-height: 900px
    }
}

@media(max-width:767px) {
    .hero-slider-three .single-hero-slide {
        min-height: 700px;
        padding-top: 180px
    }
}

.hero-slider-three .slide-wave {
    left: 0;
    bottom: -2px;
    z-index: 1;
    width: 100%;
    height: 397px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover
}

@media(max-width:1400px) {
    .hero-slider-three .slide-wave {
        height: 300px;
        bottom: -4px
    }
}

@media(max-width:991px) {
    .hero-slider-three .slide-wave {
        height: 180px
    }
}

@media(max-width:767px) {
    .hero-slider-three .slide-wave {
        height: 130px;
        width: 101%;
        left: -2px;
        bottom: -1px
    }
}

@media(max-width:480px) {
    .hero-slider-three .slide-wave {
        height: 120px;
        bottom: -1px
    }
}

.hero-slider-four {
    position: relative
}

.hero-slider-four .single-hero-slide {
    z-index: 1;
    overflow: hidden;
    position: relative;
    min-height: 900px;
    background: #fff;
    padding-top: 307px
}

@media(max-width:991px) {
    .hero-slider-four .single-hero-slide::before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
        z-index: -1;
        background: #f4b930
    }
}

@media(max-width:991px) {
    .hero-slider-four .single-hero-slide {
        min-height: 680px;
        padding-top: 220px
    }
}

@media(max-width:480px) {
    .hero-slider-four .single-hero-slide {
        min-height: 700px;
        padding-top: 180px
    }
}

.hero-slider-four .single-hero-slide .slide-content h1 {
    color: #232328;
    margin-top: -12px;
    line-height: 60px;
    padding-right: 70px;
    margin-bottom: 24px;
    font-weight: 700
}

@media(max-width:1200px) {
    .hero-slider-four .single-hero-slide .slide-content h1 {
        line-height: 1.3;
        font-size: 40px
    }
}

@media(max-width:991px) {
    .hero-slider-four .single-hero-slide .slide-content h1 {
        font-size: 36px;
        font-weight: 700;
        padding-right: 10px
    }
}

@media(max-width:480px) {
    .hero-slider-four .single-hero-slide .slide-content h1 {
        font-size: 32px;
        padding-right: 30px
    }
}

.hero-slider-four .single-hero-slide .slide-content p {
    font-size: 19px;
    line-height: 35px;
    color: #555;
    padding-right: 10px
}

@media(max-width:767px) {
    .hero-slider-four .single-hero-slide .slide-content p {
        font-size: 17px
    }
}

.hero-slider-four .single-hero-slide .slide-content .slide-round-btn {
    color: #fff;
    font-size: 19px;
    margin-top: 48px;
    line-height: 35px;
    margin-right: 25px;
    border-radius: 50px;
    padding: 14px 44.5px;
    display: inline-block;
    box-sizing: border-box;
    transition: all .4s ease-in-out;
    background: #2e77ff;
    text-transform: capitalize;
    border: 1px solid transparent
}

.hero-slider-four .single-hero-slide .slide-content .slide-round-btn i {
    margin-left: 20px
}

.hero-slider-four .single-hero-slide .slide-content .slide-round-btn:hover {
    color: #fff;
    background: #f4b930
}

@media(max-width:991px) {
    .hero-slider-four .single-hero-slide .slide-content .slide-round-btn {
        margin-top: 28px;
        padding: 10px 32px
    }
}

@media(max-width:480px) {
    .hero-slider-four .single-hero-slide .slide-content .slide-round-btn {
        font-size: 15px;
        line-height: 30px
    }
}

.hero-slider-four .slide-bg {
    position: absolute;
    right: -10%;
    width: 70%;
    height: 100%;
    content: "";
    background-size: cover;
    bottom: -3%;
    mix-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: bottom
}

@media(max-width:1500px) {
    .hero-slider-four .slide-bg {
        right: -25%;
        width: 80%
    }
}

@media(max-width:1200px) {
    .hero-slider-four .slide-bg {
        bottom: -16%;
        width: 100%
    }
}

@media(max-width:991px) {
    .hero-slider-four .slide-bg {
        display: none;
        opacity: 0
    }
}

.hero-animate {
    position: relative;
    margin-top: -100px
}

.hero-animate .animate {
    position: absolute
}

.hero-animate .animate.animate1 {
    left: 48px
}

.hero-animate .animate.animate2 {
    top: 116px;
    left: 219px
}

.hero-animate .animate.animate3 {
    left: 400px
}

@media(max-width:1200px) {
    .hero-animate {
        margin-top: 50px
    }
}

@media(max-width:991px) {
    .main-menu .pl-60 {
        padding-left: 15px
    }
    .main-menu .pr-60 {
        padding-right: 15px
    }
}

.hero-five {
    position: relative
}

.hero-five .single-hero {
    z-index: 1;
    position: relative;
    padding-top: 240px;
    padding-bottom: 140px;
    background-color: #fff8ed
}

@media(max-width:1366px) {
    .hero-five .single-hero {
        padding-top: 200px;
        padding-bottom: 120px
    }
}

@media(max-width:1191px) {
    .hero-five .single-hero {
        padding-top: 180px;
        padding-bottom: 100px
    }
}

@media(max-width:1191px) {
    .hero-five .single-hero {
        padding-top: 120px;
        padding-bottom: 80px
    }
}

.hero-five .single-hero:before {
    left: 0;
    bottom: 0;
    z-index: -1;
    content: "";
    width: 40%;
    height: 738px;
    position: absolute;
    background: #dad9d4;
    border-radius: 0 30px 0 0
}

@media(max-width:1366px) {
    .hero-five .single-hero:before {
        width: 38%;
        height: 560px
    }
}

@media(max-width:1191px) {
    .hero-five .single-hero:before {
        display: none
    }
}

.hero-five .single-hero:after {
    left: 20%;
    bottom: 0;
    z-index: -11;
    content: "";
    width: 485px;
    height: 759px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    /* s */
    border-radius: 0 30px 0 0
}

@media(max-width:1366px) {
    .hero-five .single-hero:after {
        height: 600px
    }
}

@media(max-width:1191px) {
    .hero-five .single-hero:after {
        display: none
    }
}

.hero-five .single-hero .hero-img {
    left: 0;
    bottom: 0;
    position: absolute
}

@media(max-width:1366px) {
    .hero-five .single-hero .hero-img img {
        max-width: 70%
    }
}

.hero-five .single-hero .hero-content span {
    line-height: 1;
    color: #04004d;
    border-radius: 5px;
    padding: 10px 30px;
    margin-bottom: 15px;
    display: inline-block;
    text-transform: uppercase;
    background-color: #ffedd2
}

@media(max-width:767px) {
    .hero-five .single-hero .hero-content span {
        font-size: 14px;
        padding: 8px 24px
    }
}

.hero-five .single-hero .hero-content h1 {
    font-size: 76px;
    color: #04004d;
    margin-bottom: 40px
}

@media(max-width:767px) {
    .hero-five .single-hero .hero-content h1 {
        font-size: 50px;
        line-height: 1.2;
        margin-bottom: 30px
    }
}

@media(max-width:500px) {
    .hero-five .single-hero .hero-content h1 {
        font-size: 40px;
        line-height: 1.2
    }
}

.hero-five .single-hero .hero-content p {
    color: #6d697d
}

.hero-five .follow-us-social {
    top: 50%;
    right: -80px;
    color: #04004d;
    font-weight: 500;
    position: absolute;
    transform: translateY(-50%) rotate(-90deg)
}

@media(max-width:1366px) {
    .hero-five .follow-us-social {
        right: -100px
    }
}

.hero-five .follow-us-social span {
    position: relative;
    margin-right: 10px;
    padding-right: 20px
}

.hero-five .follow-us-social span:before {
    position: absolute;
    right: 0;
    content: "-"
}

.hero-five .follow-us-social a {
    color: #04004d;
    font-weight: 500;
    position: relative;
    margin-right: 10px;
    padding-right: 15px;
    display: inline-block
}

.hero-five .follow-us-social a:hover {
    color: #785fff
}

.hero-five .follow-us-social a:before {
    position: absolute;
    right: 0;
    content: "/"
}

.hero-five .follow-us-social a:last-child:before {
    display: none
}

.theme-btn-vr {
    color: #fff;
    line-height: 1;
    transition: .3s;
    font-weight: 500;
    margin-top: 40px;
    padding: 24px 35px;
    border-radius: 5px;
    display: inline-block;
    background-color: #785fff
}

@media(max-width:991px) {
    .theme-btn-vr {
        font-size: 15px;
        padding: 16px 32px
    }
}

.theme-btn-vr:hover {
    color: #fff;
    background-color: #04004d
}

.about-wrap {
    border-bottom: 1px solid #e8ecef;
    background-image: -ms-linear-gradient(90deg, #f7f9fe 0%, white 100%)
}

.about-wrap .about-content {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    padding: 88px 70px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1)
}

@media(max-width:670px) {
    .about-wrap .about-content {
        padding: 50px 30px
    }
}

@media(max-width:670px) {
    .about-wrap .about-content {
        padding: 50px 15px
    }
}

.about-wrap .about-content h2 {
    margin-top: -12px
}

@media(max-width:500px) {
    .about-wrap .about-content h2 {
        margin-top: 0
    }
}

.about-wrap .about-content h2 span {
    position: relative;
    color: #2e77ff;
    display: inline-block
}

.about-wrap .about-content h2 span::before {
    top: 58%;
    width: 80px;
    content: "";
    height: 5px;
    right: -80px;
    position: absolute;
    background: #2e77ff;
    transform: translateY(-50%)
}

@media(max-width:480px) {
    .about-wrap .about-content h2 span::before {
        width: 20px;
        height: 3px;
        right: -25px
    }
}

.about-wrap .about-content p {
    margin-top: 38px;
    margin-bottom: -7px
}

.about-wrap .about-content .about-content-pattern {
    right: 0;
    bottom: 0;
    content: "";
    width: 217px;
    height: 136px;
    position: absolute
}

@media(max-width:767px) {
    .about-wrap .about-content .about-content-pattern {
        display: none;
        opacity: 0
    }
}

.about-wrap .vr-video-wrap {
    width: 100%;
    height: 525px;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 33px 46px 0 rgba(0, 0, 0, .1)
}

.about-wrap .vr-video-wrap a {
    top: 50%;
    left: 50%;
    z-index: 999;
    width: 100px;
    height: 100px;
    color: #232328;
    font-size: 16px;
    background: #fff;
    position: absolute;
    line-height: 100px;
    border-radius: 50%;
    display: inline-block;
    transform: translate(-50%, -50%);
    animation: playVideo 2s linear infinite;
    -webkit-animation: playVideo 2s linear infinite
}

.about-wrap .about-shape-wrap .shape {
    right: 0;
    left: auto;
    z-index: -1
}

@media(max-width:1600px) {
    .about-wrap .about-shape-wrap .shape {
        margin-right: -100px
    }
}

.about-wrap .about-shape-wrap .shape.circle {
    left: 0;
    top: 50%;
    right: auto;
    transform: translateY(-50%)
}

.about-wrap .about-shape-wrap img.shape.shape1 {
    top: 113px
}

.about-wrap .about-shape-wrap img.shape.shape2 {
    top: 128px;
    right: 117px !important
}

.about-wrap .about-shape-wrap img.shape.shape3 {
    right: 177px !important;
    top: 78px
}

.about-wrap .about-shape-wrap img.shape.shape4 {
    top: 154px;
    right: 42px !important
}

.about-wrap .about-shape-wrap img.shape.shape5 {
    top: 10px;
    right: 115px !important
}

.about-wrap .about-shape-wrap img.shape.shape6 {
    top: 95px;
    right: 70px !important
}

.features-section-wrap .single-features-item {
    margin-top: 50px;
    padding-right: 30px;
    transition: all .4s ease-in-out
}

.features-section-wrap .single-features-item .features-icon {
    border-radius: 50%;
    background: #eef3fe;
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    margin-bottom: 46px;
    transition: all .4s ease-in-out
}

.features-section-wrap .single-features-item .features-icon i {
    line-height: 90px;
    font-size: 26px
}

.features-section-wrap .single-features-item .features-icon.icon2 {
    background: #f9eeee
}

.features-section-wrap .single-features-item .features-icon.icon3 {
    background: #e5f9f3
}

@media(max-width:767px) {
    .features-section-wrap .single-features-item .features-icon {
        margin: 0 auto;
        margin-bottom: 46px
    }
}

.features-section-wrap .single-features-item h4 {
    margin-top: -7px;
    margin-bottom: 17px
}

.features-section-wrap .single-features-item p {
    font-size: 15px;
    line-height: 30px
}

.features-section-wrap .single-features-item:hover .icon1 {
    background: #497ef4;
    color: #fff
}

.features-section-wrap .single-features-item:hover .icon1 img {
    filter: brightness(100)
}

.features-section-wrap .single-features-item:hover .icon2 {
    background: #dd422e;
    color: #fff
}

.features-section-wrap .single-features-item:hover .icon2 img {
    filter: brightness(100)
}

.features-section-wrap .single-features-item:hover .icon3 {
    background: #30af8c;
    color: #fff
}

.features-section-wrap .single-features-item:hover .icon3 img {
    filter: brightness(100)
}

@media(max-width:1199px) {
    .features-section-wrap .single-features-item.pl-50,
    .features-section-wrap .single-features-item.pl-25 {
        padding-left: 0
    }
}

@media(max-width:991px) {
    .features-section-wrap .single-features-item {
        padding-right: 0
    }
}

@media(max-width:767px) {
    .features-section-wrap .single-features-item {
        text-align: center;
        padding-right: 0
    }
}

.fetaures-two-wrapper::before {
    border-radius: 50%;
    background-color: #f7f9fe;
    position: absolute;
    width: 890px;
    height: 890px;
    content: "";
    right: -265px;
    top: 0
}

@media(max-width:991px) {
    .fetaures-two-wrapper::before {
        right: -350px;
        top: 100px
    }
}

.fetaures-two-wrapper .features-header h2 {
    font-weight: 400;
    margin-right: -25px
}

.fetaures-two-wrapper .features-list .single-feature-item {
    margin-top: 45px;
    display: inline-block
}

.fetaures-two-wrapper .features-list .single-feature-item .feature-icon {
    overflow: auto;
    margin-right: 37px
}

.fetaures-two-wrapper .features-list .single-feature-item .feature-content {
    overflow: auto
}

.fetaures-two-wrapper .features-list .single-feature-item .feature-content h4 {
    margin-top: -6px;
    margin-bottom: 10px
}

.fetaures-two-wrapper .features-list .single-feature-item .feature-content p {
    color: #5d5d5d;
    font-size: 16px;
    line-height: 30px
}

.fetaures-two-wrapper .features-featured-img {
    margin-right: -250px
}

.fetaures-two-wrapper .features-featured-img img {
    mix-blend-mode: multiply
}

@media(max-width:1400px) {
    .fetaures-two-wrapper .features-featured-img {
        margin-left: -100px
    }
}

@media(max-width:1191px) {
    .fetaures-two-wrapper .features-featured-img {
        margin-left: -115px
    }
}

@media(max-width:991px) {
    .fetaures-two-wrapper .features-featured-img {
        margin-left: 0;
        width: 100%
    }
}

.fetaures-two-wrapper .shape.fs1 {
    left: 280px;
    bottom: 280px;
    animation: rotate3d 4s infinite linear
}

@media(max-width:1400px) {
    .fetaures-two-wrapper .shape.fs1 {
        left: 50px;
        bottom: 300px
    }
}

.fetaures-two-wrapper .shape.fs2 {
    top: 28%;
    left: 125px;
    animation: bounce 6s infinite linear
}

@media(max-width:1400px) {
    .fetaures-two-wrapper .shape.fs2 {
        top: 20%;
        left: 55px
    }
}

.feature-wraper-five {
    margin-top: -40px
}

.feature-wraper-five .single-feature-item {
    margin-top: 40px;
    padding-right: 50px
}

@media(max-width:1366px) {
    .feature-wraper-five .single-feature-item {
        padding-right: 10px
    }
}

@media(max-width:500px) {
    .feature-wraper-five .single-feature-item {
        padding-right: 0;
        text-align: center;
        padding: 0 30px
    }
}

.feature-wraper-five .single-feature-item .feature-info {
    margin-top: 40px
}

@media(max-width:767px) {
    .feature-wraper-five .single-feature-item .feature-info {
        margin-top: 25px
    }
}

.feature-wraper-five .single-feature-item .feature-info h3 {
    margin-bottom: 15px
}

.feature-wraper-five .single-feature-item .feature-info p {
    color: #747483
}

.feature-wraper-five .single-feature-item.item1 .feature-icon img {
    filter: drop-shadow(0px 10px 40px rgba(15, 197, 15, 0.12))
}

.feature-wraper-five .single-feature-item.item2 .feature-icon img {
    filter: drop-shadow(0px 10px 40px rgba(115, 225, 249, 0.12))
}

.feature-wraper-five .single-feature-item.item3 .feature-icon img {
    filter: drop-shadow(0px 10px 40px rgba(255, 185, 2, 0.12))
}

.feature-wraper-five .single-feature-item.item4 .feature-icon img {
    filter: drop-shadow(0px 10px 40px rgba(120, 95, 255, 0.12))
}

.services-wrap::before {
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: -ms-linear-gradient(90deg, #f7f9fe 0%, white 100%);
    opacity: .5
}

.services-wrap .service-right-bg {
    right: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: contain;
    mix-blend-mode: multiply;
    background-position: right;
    background-repeat: no-repeat
}

.services-wrap .service-right-bg:before {
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    content: "";
    width: 100%;
    height: 100%;
    opacity: .9
}

@media(max-width:1600px) {
    .services-wrap .service-right-bg {
        display: none;
        opacity: 0
    }
}

@media(max-width:767px) {
    .services-wrap .service-head-content.pr-40 {
        padding-right: 0
    }
}

.services-wrap .single-service-item {
    z-index: 1;
    margin-top: 75px;
    position: relative
}

.services-wrap .single-service-item .service-icon {
    float: left;
    width: 85px;
    height: 85px;
    overflow: auto;
    line-height: 85px;
    text-align: center;
    border-radius: 50%;
    margin-right: 25px;
    box-shadow: 0 5px 15px 0 rgba(5, 60, 161, .1);
    background-color: #fff;
    transition: all .4s ease-in-out
}

.services-wrap .single-service-item .service-icon i {
    display: block;
    font-size: 40px;
    line-height: 85px;
    color: #2e77ff
}

@media(max-width:480px) {
    .services-wrap .single-service-item .service-icon {
        float: none;
        margin-right: 0;
        margin-bottom: 25px
    }
}

.services-wrap .single-service-item .service-info {
    overflow: auto
}

.services-wrap .single-service-item .service-info h4 {
    margin-top: -6px;
    margin-bottom: 17px
}

.services-wrap .single-service-item .service-info p {
    font-size: 15px;
    line-height: 30px
}

@media(max-width:1400px) {
    .services-wrap .service-shape-wrap {
        display: none;
        opacity: 0
    }
}

.services-wrap .service-shape-wrap .shape {
    z-index: -1
}

@media(max-width:1600px) {
    .services-wrap .service-shape-wrap .shape {
        margin-left: -100px
    }
}

.services-wrap .service-shape-wrap .shape.s5 {
    transform: rotate(180deg);
    left: 64px;
    bottom: 0
}

.services-wrap .service-shape-wrap .shape.s7 {
    bottom: 120px;
    left: -20px
}

.services-wrap .service-shape-wrap .shape.s4 {
    left: -5px;
    bottom: 145px
}

.services-wrap .service-shape-wrap .shape.s6 {
    left: 20px;
    bottom: 91px
}

.services-wrap .service-shape-wrap .shape.s2 {
    left: 75px;
    bottom: 122px;
    transform: rotate(180deg)
}

.services-wrap .service-shape-wrap .shape.s3 {
    left: 130px;
    bottom: 70px
}

.services-section-two .services-item-grid {
    margin-top: 45px
}

.services-section-two .services-item-grid .single-service-item {
    margin-left: -44px;
    margin-bottom: 65px;
    padding-right: 56px
}

@media(max-width:1199px) {
    .services-section-two .services-item-grid .single-service-item {
        margin-left: 15px;
        padding-right: 20px
    }
}

@media(max-width:400px) {
    .services-section-two .services-item-grid .single-service-item {
        text-align: center
    }
}

.services-section-two .services-item-grid .single-service-item .service-icon {
    float: left;
    width: 116px;
    height: 102px;
    text-align: center;
    line-height: 102px;
    margin-right: 28px;
    position: relative
}

.services-section-two .services-item-grid .single-service-item .service-icon::before {
    top: 5px;
    z-index: -1;
    width: 71px;
    content: "";
    left: -15px;
    height: 72px;
    border-radius: 50%;
    position: absolute;
    background-color: #fcfcfc;
    box-shadow: 0 5px 68px 0 rgba(0, 0, 0, .05)
}

.services-section-two .services-item-grid .single-service-item .service-icon i {
    font-size: 40px;
    line-height: 102px;
    color: #2e77ff
}

@media(max-width:450px) {
    .services-section-two .services-item-grid .single-service-item .service-icon {
        float: none;
        text-align: center;
        position: relative;
        margin: 0 auto;
        margin-bottom: 30px
    }
}

.services-section-two .services-item-grid .single-service-item .service-info {
    overflow: auto
}

.services-section-two .services-item-grid .single-service-item .service-info h4 {
    margin-bottom: 14px
}

.services-section-two .services-item-grid .single-service-item .service-info p {
    font-size: 15px;
    line-height: 30px
}

@media(max-width:450px) {
    .services-section-two .services-item-grid .single-service-item .service-info {
        text-align: center
    }
}

.services-section-two .services-item-grid .more-service-btn {
    margin: 0 auto;
    margin-top: 27px;
    text-align: center
}

.services-section-two .services-item-grid .more-service-btn a {
    min-width: 220px;
    min-height: 70px;
    font-weight: 500;
    padding: 0 57px;
    color: #232328;
    line-height: 70px;
    border-radius: 35px;
    display: inline-block;
    background-color: #fff;
    transition: all .4s ease-in-out;
    box-shadow: 0 5px 38px 0 rgba(5, 60, 161, .05)
}

.services-section-two .services-item-grid .more-service-btn a:hover {
    color: #fff;
    background: #2e77ff
}

@media(max-width:767px) {
    .services-section-two .services-item-grid .more-service-btn a {
        min-width: 160px;
        min-height: 60px;
        font-weight: 700;
        padding: 0 25px;
        color: #232328;
        line-height: 60px;
        font-size: 14px
    }
}

.services-section-two .service-two-shape .shape {
    z-index: -1
}

.services-section-two .service-two-shape .shape.st5 {
    right: 0;
    bottom: 15px
}

.services-section-two .service-two-shape .shape.st1 {
    left: 179px;
    animation: bounce 4s infinite linear
}

.services-section-two .service-two-shape .shape.st2 {
    left: 52px;
    bottom: 470px;
    animation: movebounce 4s infinite linear
}

.services-section-two .service-two-shape .shape.st3 {
    right: 270px;
    top: 180px;
    animation: rotate3d 4s infinite linear
}

.services-section-two .service-two-shape .shape.st4 {
    right: 233px;
    bottom: 138px;
    filter: blur(3px);
    animation: bounce 4s infinite linear
}

.tag-title {
    height: 40px;
    font-size: 16px;
    min-width: 120px;
    line-height: 40px;
    padding: 0 28px;
    text-align: center;
    background: #e8edfd;
    border-radius: 20px;
    margin-bottom: 26px;
    color: #2e77ff;
    display: inline-block;
    transition: all .4s ease-in-out
}

.tag-title:hover {
    color: #fff;
    background: #2e77ff
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study {
    margin: 0 10px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, .1);
    max-width: 545px;
    height: 640px;
    position: relative;
    transition: all .45s ease-in-out;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    margin-top: 50px
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #2e77ff;
    transition: all .45s ease-in-out;
    opacity: 0;
    visibility: hidden;
    height: 0%
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study:hover::before {
    opacity: .95;
    visibility: visible;
    height: 100%
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study:hover .case-hover-content {
    opacity: 1;
    visibility: visible
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study:hover .case-hover-content .case-title {
    transition-delay: .4s;
    transform: translateY(0)
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study .case-hover-content {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all .45s ease-in-out;
    opacity: 0;
    visibility: hidden
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study .case-hover-content .case-icon {
    top: 50%;
    position: relative;
    text-align: center;
    transform: translateY(-50%)
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study .case-hover-content .case-icon a {
    border-width: 1px;
    border-color: #fff;
    border-style: solid;
    border-radius: 50%;
    width: 84px;
    height: 84px;
    color: #fff;
    text-align: center;
    line-height: 84px;
    font-size: 30px;
    display: inline-block;
    transition: all .4s ease-in-out
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study .case-hover-content .case-icon a i {
    transition: all .4s ease-in-out
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study .case-hover-content .case-icon a:hover {
    background: #f4b930;
    border-color: transparent
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study .case-hover-content .case-title {
    position: absolute;
    bottom: 0;
    padding-left: 51px;
    padding-bottom: 56px;
    padding-right: 51px;
    transform: translateY(100%);
    transition: all .6s ease-in-out
}

.case-studies-wrap .case-study-carousel-wrap .single-case-study .case-hover-content .case-title h4 {
    color: #fff
}

@media(max-width:1400px) {
    .case-studies-wrap .case-study-carousel-wrap .single-case-study {
        height: 550px
    }
}

@media(max-width:991px) {
    .case-studies-wrap .case-study-carousel-wrap .single-case-study {
        height: 500px
    }
}

@media(max-width:500px) {
    .case-studies-wrap .case-study-carousel-wrap .single-case-study {
        height: 400px
    }
}

.case-studies-wrap .case-study-carousel-wrap .slick-dots {
    position: relative;
    bottom: 0;
    display: block
}

.case-studies-wrap .case-study-carousel-wrap .slick-dots li {
    position: relative;
    padding: 0;
    list-style: none;
    text-align: center;
    margin-top: 57px;
    max-height: 20px;
    overflow: hidden;
    line-height: 1
}

.case-studies-wrap .case-study-carousel-wrap .slick-dots li button {
    border-radius: 50%;
    background: #e9edf4;
    width: 10px;
    height: 10px;
    position: relative;
    display: inline-block;
    transition: all .2s ease-in-out
}

.case-studies-wrap .case-study-carousel-wrap .slick-dots li button::before {
    display: none;
    opacity: 0
}

.case-studies-wrap .case-study-carousel-wrap .slick-dots li.slick-active button {
    border-radius: 50%;
    background: #2e77ff;
    width: 12px;
    height: 12px;
    transition: all .2s ease-in-out
}

.case-studies-wrap .case-study-carousel-wrap .slick-list {
    overflow: inherit
}

.case-studies-wrap .case-study-carousel-wrap.slick-dotted.slick-slider {
    margin-bottom: 0;
    margin-bottom: -10px
}

.case-study-section {
    overflow-x: hidden
}

.case-study-section .case-study-img {
    position: absolute;
    top: 0;
    max-width: 765px;
    left: -110px;
    z-index: -1
}

@media(max-width:1460px) {
    .case-study-section .case-study-img {
        max-width: 700px;
        left: -125px
    }
}

@media(max-width:1290px) {
    .case-study-section .case-study-img {
        max-width: 665px;
        left: -145px
    }
}

@media(max-width:991px) {
    .case-study-section .case-study-img {
        max-width: 100%;
        left: 0;
        margin: 0 auto;
        text-align: center;
        position: relative;
        top: 0
    }
    .case-study-section .case-study-img img {
        width: 50%
    }
}

@media(max-width:500px) {
    .case-study-section .case-study-img img {
        width: 70%
    }
}

@media(max-width:991px) {
    .case-study-section .case-study-content {
        margin-top: 30px
    }
}

.case-study-section .case-study-content .case-header h2 {
    font-weight: 700
}

.case-study-section .case-study-content p {
    font-size: 15px;
    line-height: 30px
}

.case-study-section .case-two-shape .shape.cs1 {
    right: 426px;
    top: 30px;
    animation: bounce 5s infinite linear
}

.case-study-section .case-two-shape .shape.cs2 {
    right: 227px;
    top: 67%;
    animation: movebounce 4s infinite linear
}

.case-study-section .case-two-shape .shape.cs3 {
    right: 178px;
    top: 28%;
    animation: rotate3d 5s infinite linear
}

.testimonial-wrap .testimonial-items-list .single-testimonial {
    margin: 0 auto;
    margin-bottom: -10px;
    padding: 0 30px;
    transition: all .4s ease-in-out
}

@media(max-width:500px) {
    .testimonial-wrap .testimonial-items-list .single-testimonial {
        text-align: center;
        padding: 0
    }
}

@media(max-width:767px) {
    .testimonial-wrap .testimonial-items-list .single-testimonial {
        padding: 0 50px
    }
}

.testimonial-wrap .testimonial-items-list .single-testimonial .client-img {
    border-style: solid;
    border-width: 1px;
    border-color: #eaeaea;
    border-radius: 50%;
    background-color: #000;
    width: 95px;
    height: 95px;
    float: left;
    overflow: auto;
    margin-right: 39px
}

@media(max-width:767px) {
    .testimonial-wrap .testimonial-items-list .single-testimonial .client-img {
        margin-right: 15px
    }
}

@media(max-width:500px) {
    .testimonial-wrap .testimonial-items-list .single-testimonial .client-img {
        margin-right: 0;
        float: none;
        margin: 0 auto;
        margin-bottom: 20px
    }
}

.testimonial-wrap .testimonial-items-list .single-testimonial .client-feedback {
    overflow: auto
}

.testimonial-wrap .testimonial-items-list .single-testimonial .client-feedback h4 {
    font-size: 25px;
    line-height: 43px;
    font-weight: 300;
    font-style: italic;
    margin-top: -13px;
    margin-bottom: 17px
}

@media(max-width:1400px) {
    .testimonial-wrap .testimonial-items-list .single-testimonial .client-feedback h4 {
        font-size: 21px;
        line-height: 36px;
        margin-top: -9px
    }
}

@media(max-width:1199px) {
    .testimonial-wrap .testimonial-items-list .single-testimonial .client-feedback h4 {
        font-size: 20px;
        line-height: 36px;
        margin-top: -9px
    }
}

@media(max-width:767px) {
    .testimonial-wrap .testimonial-items-list .single-testimonial .client-feedback h4 {
        font-size: 18px;
        line-height: 30px;
        margin-top: -7px
    }
}

.testimonial-wrap .testimonial-items-list .single-testimonial .rating-icons {
    margin-bottom: 16px;
    transition: all .4s ease-in-out;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    display: none
}

.testimonial-wrap .testimonial-items-list .single-testimonial .rating-icons span {
    line-height: 1 !important;
    font-size: 20px;
    color: #f5c24c;
    transition: .3s
}

@media(max-width:1600px) {
    .testimonial-wrap .testimonial-items-list .single-testimonial .rating-icons {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        display: block
    }
}

.testimonial-wrap .testimonial-items-list .single-testimonial .client-info h5 {
    color: #5b5b5f
}

.testimonial-wrap .testimonial-items-list .single-testimonial .client-info span,
.testimonial-wrap .testimonial-items-list .single-testimonial .client-info h6 {
    color: #4f4f53
}

.testimonial-wrap .testimonial-items-list .single-testimonial.slick-slide {
    opacity: .1;
    transition: all .4s ease-in-out
}

@media(max-width:767px) {
    .testimonial-wrap .testimonial-items-list .single-testimonial.slick-slide {
        opacity: .5;
        padding: 0 10px
    }
}

.testimonial-wrap .testimonial-items-list .single-testimonial.slick-current.slick-active {
    opacity: 1
}

.testimonial-wrap .testimonial-items-list .single-testimonial.slick-current.slick-active .rating-icons {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    display: block;
    transition: all .4s ease-in-out
}

.testimonial-wrap .testimonial-items-list .slick-arrow {
    border-width: 1px;
    border-color: #ddd;
    border-style: solid;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 9px 29px 0 rgba(0, 0, 0, .1);
    width: 54px;
    height: 54px;
    text-align: center;
    line-height: 54px;
    color: #232328;
    position: absolute;
    left: -13%;
    top: 22%;
    transform: translate(-50%);
    transition: .35s;
    z-index: 9
}

@media(max-width:1200px) {
    .testimonial-wrap .testimonial-items-list .slick-arrow {
        left: -2%
    }
}

@media(max-width:991px) {
    .testimonial-wrap .testimonial-items-list .slick-arrow {
        left: 0%
    }
}

@media(max-width:767px) {
    .testimonial-wrap .testimonial-items-list .slick-arrow {
        display: none !important
    }
}

.testimonial-wrap .testimonial-items-list .slick-arrow:hover {
    background: #2e77ff;
    color: #fff;
    border-color: #2e77ff;
    cursor: pointer
}

.testimonial-wrap .testimonial-items-list .fa-chevron-right.slick-arrow {
    left: auto;
    right: -13%
}

@media(max-width:991px) {
    .testimonial-wrap .testimonial-items-list .fa-chevron-right.slick-arrow {
        right: -5%
    }
}

.testimonial-wrap .testimonial-items-list .slick-list.draggable {
    overflow: unset
}

.testimonial-wrap .testimonial-items-list.slick-dotted.slick-slider {
    margin-bottom: 0
}

.testimonial-wrap .testimonial-items-list .slick-dots {
    bottom: 0;
    display: none !important;
    visibility: hidden;
    opacity: 0
}

.testimonial-wrap .testimonial-items-list.testimonial-two .single-testimonial {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 54px;
    padding-bottom: 40px;
    border-width: 1px;
    border-color: #e8edf0;
    border-style: solid;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 15px 50px 0 rgba(0, 0, 0, .05)
}

@media(max-width:991px) {
    .testimonial-wrap .testimonial-items-list.testimonial-two .single-testimonial {
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 44px;
        padding-bottom: 30px
    }
}

.testimonial-wrap .testimonial-items-list.testimonial-two .slick-dots {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important
}

.testimonial-wrap .testimonial-items-list.testimonial-two .slick-dots {
    position: relative;
    bottom: 0;
    display: block
}

.testimonial-wrap .testimonial-items-list.testimonial-two .slick-dots li {
    position: relative;
    padding: 0;
    list-style: none;
    text-align: center;
    margin-top: 57px;
    max-height: 20px;
    overflow: hidden;
    line-height: 1
}

.testimonial-wrap .testimonial-items-list.testimonial-two .slick-dots li button {
    border-radius: 50%;
    background: #e9edf4;
    width: 10px;
    height: 10px;
    position: relative;
    display: inline-block;
    transition: all .2s ease-in-out
}

.testimonial-wrap .testimonial-items-list.testimonial-two .slick-dots li button::before {
    display: none;
    opacity: 0
}

.testimonial-wrap .testimonial-items-list.testimonial-two .slick-dots li.slick-active button {
    border-radius: 50%;
    background: #2e77ff;
    width: 12px;
    height: 12px;
    transition: all .2s ease-in-out
}

.testimonial-wrap .testimonial-items-list.testimonial-two .slick-list {
    overflow: inherit
}

.testimonial-wrap .testimonial-items-list.testimonial-two.slick-dotted.slick-slider {
    margin-bottom: 0;
    margin-bottom: -10px
}

.testimonial-block {
    background-color: #f9f9f9
}

.testimonial-block .testimonial-banner {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    content: "";
    background-size: cover
}

.testimonial-block .testimonial-item .review-text {
    color: #6d697d
}

.testimonial-block .testimonial-item .reviewer .r-img {
    width: 78px;
    height: 78px;
    border-radius: 50px
}

.testimonial-block .slick-arrow {
    position: absolute;
    bottom: 0;
    width: 47px;
    height: 47px;
    line-height: 47px;
    color: #04004d;
    background: #f1f1f1;
    text-align: center;
    border-radius: 30px;
    left: 0;
    right: auto;
    z-index: 1;
    cursor: pointer
}

.testimonial-block .fal.fa-arrow-right.slick-arrow {
    left: 60px
}

.testimonial-block .slick-slider {
    padding-bottom: 95px
}

.call-to-action-wrap {
    background-image: linear-gradient(165deg, #ff8929 0%, #ff8929 100%)
}

.call-to-action-wrap .cta-banner {
    padding: 80px 0;
    background: #fff;
    border-radius: 10px
}

.call-to-action-wrap .cta-banner h2 {
    margin-bottom: 25px;
    margin-top: -12px
}

.call-to-action-wrap .cta-banner p {
    color: #555
}

.call-to-action-wrap .cta-banner .cta-btn {
    color: #fff;
    font-size: 19px;
    line-height: 35px;
    padding: 20px 36px;
    border-radius: 5px;
    display: inline-block;
    transition: all .35s ease-in-out;
    background: #f4b930;
    margin-top: 50px
}

.call-to-action-wrap .cta-banner .cta-btn i {
    margin-left: 40px;
    display: inline-block
}

@media(max-width:767px) {
    .call-to-action-wrap .cta-banner .cta-btn i {
        margin-left: 15px
    }
}

@media(max-width:480px) {
    .call-to-action-wrap .cta-banner .cta-btn i {
        margin-left: 10px
    }
}

.call-to-action-wrap .cta-banner .cta-btn:hover {
    background: #2e77ff
}

@media(max-width:767px) {
    .call-to-action-wrap .cta-banner .cta-btn {
        font-size: 15px;
        line-height: 30px;
        padding: 20px 30px;
        margin-top: 30px
    }
}

@media(max-width:480px) {
    .call-to-action-wrap .cta-banner .cta-btn {
        font-size: 14px;
        line-height: 30px;
        padding: 20px;
        text-align: center
    }
}

.call-to-action-wrap .call-to-action-shape-wrap .shape.cta1 {
    right: 283px;
    top: 310px
}

.call-to-action-wrap .call-to-action-shape-wrap .shape.cta2 {
    top: -25px;
    left: 355px
}

.call-to-action-wrap .call-to-action-shape-wrap .shape.cta3 {
    right: 485px;
    top: 45px
}

.call-to-action-wrap .call-to-action-shape-wrap .shape.cta4 {
    left: 184px;
    top: 48%
}

@media(max-width:1500px) {
    .call-to-action-wrap .call-to-action-shape-wrap .shape.cta4 {
        display: none
    }
}

.call-to-action-wrap .call-to-action-shape-wrap .shape.cta5 {
    right: 139px;
    bottom: 226px;
    filter: brightness(1.2)
}

.call-to-action-wrap .call-to-action-shape-wrap .shape.cta6 {
    left: 222px;
    bottom: 150px
}

@media(max-width:1400px) {
    .call-to-action-wrap .call-to-action-shape-wrap .shape.cta6 {
        left: 40px;
        bottom: 67px
    }
}

.call-to-action-wrap .call-to-action-shape-wrap .shape.cta7 {
    right: 181px;
    top: 180px
}

.call-to-action-wrap.cta-two-wrap .cta-banner {
    background: 0 0;
    padding: 0
}

.call-to-action-wrap.cta-two-wrap .cta-banner h2 {
    color: #fff;
    font-weight: 400
}

.call-to-action-wrap.cta-two-wrap .cta-banner p {
    color: #ddd;
    font-weight: 300
}

.call-to-action-wrap.cta-two-wrap .cta-banner .cta-btn {
    border-radius: 37.5px;
    border: 1px solid #547cc7;
    background-color: transparent;
    color: #fff
}

.call-to-action-wrap.cta-two-wrap .cta-banner .cta-btn:hover {
    color: #fff;
    background: #f4b930;
    border-color: #f4b930
}

.vr-cta-wrap .container {
    display: block;
    padding-top: 120px;
    padding-bottom: 120px;
    background-color: #fff8ed
}

@media(max-width:991px) {
    .vr-cta-wrap .container {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media(max-width:767px) {
    .vr-cta-wrap .container {
        padding-top: 60px;
        padding-bottom: 60px
    }
}

.vr-cta-wrap h1 {
    line-height: 52px;
    font-size: 42px
}

@media(max-width:767px) {
    .vr-cta-wrap h1 {
        font-size: 34px;
        line-height: 44px
    }
}

.vr-cta-wrap img {
    margin-top: -40px;
    margin-bottom: -30px;
    filter: drop-shadow(8.269px 28.838px 40px rgba(0, 0, 0, 0.18))
}

@media(max-width:991px) {
    .vr-cta-wrap img {
        margin: 0 auto;
        padding-top: 50px
    }
}

.video-promo-area {
    z-index: 1;
    height: 636px;
    position: relative
}

.video-promo-area:before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: .4;
    position: absolute;
    background-color: #000
}

@media(max-width:991px) {
    .video-promo-area {
        height: 400px
    }
}

@media(max-width:767px) {
    .video-promo-area {
        height: 350px
    }
}

.video-promo-area .popup-video {
    color: #fff;
    width: 102px;
    height: 102px;
    font-size: 36px;
    line-height: 102px;
    border-radius: 50%;
    display: inline-block;
    background-color: #171614;
    animation: playVideo 1.5s linear infinite
}

@media(max-width:767px) {
    .video-promo-area .popup-video {
        width: 80px;
        height: 80px;
        font-size: 26px;
        line-height: 80px
    }
}

.content-block-wrap {
    mix-blend-mode: multiply;
    background-size: contain;
    background-repeat: no-repeat
}

@media(max-width:1199px) {
    .content-block-wrap .content-wrap {
        margin-top: 50px
    }
}

.content-block-wrap .content-wrap .title {
    /* margin-top: -12px; */
    margin-bottom: 16px
}

.content-block-wrap .content-wrap ul {
    margin-top: 40px
}

@media(max-width:767px) {
    .content-block-wrap .content-wrap ul {
        margin-top: 20px;
        margin-bottom: 10px
    }
}

.content-block-wrap .content-wrap ul li {
    position: relative;
    padding-left: 26px;
    color: #232328
}

.content-block-wrap .content-wrap ul li::before {
    width: 5px;
    height: 5px;
    position: absolute;
    background: #232328;
    border-radius: 50%;
    content: "";
    top: 50%;
    left: 0;
    transform: translateY(-50%)
}

@media(max-width:767px) {
    .content-block-wrap .content-wrap .theme-btn {
        margin-right: 15px
    }
}

.content-block-wrap .vr-content {
    background-size: cover;
    min-height: 820px;
    mix-blend-mode: multiply;
    position: relative;
    margin-left: -200px;
    max-width: 720px;
    margin-top: -245px;
    margin-bottom: 28px
}

@media(max-width:1600px) {
    .content-block-wrap .vr-content {
        margin-left: 0;
        max-width: 100%;
        margin-top: -150px;
        min-height: 720px
    }
}

@media(max-width:991px) {
    .content-block-wrap .vr-content {
        margin-left: 0;
        max-width: 100%;
        min-height: 600px
    }
}

@media(max-width:767px) {
    .content-block-wrap .vr-content {
        margin-left: 0;
        max-width: 100%;
        min-height: 78px;
        margin-top: 50px;
        margin-bottom: 60px
    }
}

.content-block-wrap .vr-content .vr-carousel {
    position: absolute;
    bottom: -100px;
    text-align: center;
    left: 0;
    width: 620px;
    overflow: hidden;
    padding: 100px 0
}

@media(max-width:1400px) {
    .content-block-wrap .vr-content .vr-carousel {
        max-width: 100%
    }
}

@media(max-width:991px) {
    .content-block-wrap .vr-content .vr-carousel {
        padding: 80px 0
    }
}

.content-block-wrap .vr-content .vr-carousel .slick-list {
    overflow: unset;
    max-width: 100%
}

.content-block-wrap .vr-content .single-vr-carousel.slick-slide {
    margin: 0;
    min-height: 118px;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.content-block-wrap .vr-content .single-vr-carousel.slick-slide.slick-active.slick-center {
    transform: scale(1.5)
}

.block-content span {
    color: #04004d;
    line-height: 1;
    transition: .3s;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 5px;
    margin-bottom: 30px;
    display: inline-block;
    background-color: #ffedd2;
    text-transform: uppercase
}

@media(max-width:991px) {
    .block-content span {
        font-size: 14px;
        padding: 8px 22px;
        margin-bottom: 20px
    }
}

.block-content span:hover {
    color: #fff;
    background-color: #04004d
}

.block-content h1 {
    font-size: 42px;
    font-weight: 600;
    line-height: 52px;
    margin-bottom: 40px
}

@media(max-width:991px) {
    .block-content h1 {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 30px
    }
}

@media(max-width:767px) {
    .block-content h1 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 25px
    }
}

.block-content p {
    color: #747186
}

.block-content-img {
    z-index: 1;
    position: relative
}

@media(max-width:991px) {
    .block-content-img {
        display: none
    }
}

.block-content-img img {
    bottom: -36px;
    position: relative
}

.block-content-img:before {
    top: 0;
    left: 0;
    z-index: -1;
    content: "";
    width: 470px;
    height: 550px;
    border-radius: 8px;
    position: absolute;
    background-color: #d5dadd
}

.block-wraper {
    z-index: 1;
    position: relative
}

.block-wraper .shape-bg {
    position: absolute;
    bottom: -150px;
    right: 0;
    z-index: -1;
    content: ""
}

@media(max-width:1191px) {
    .block-wraper .shape-bg {
        right: -50px
    }
}

@media(max-width:991px) {
    .block-wraper .shape-bg {
        display: none
    }
}

.block-wraper .shape-bg-rotate {
    right: 0;
    bottom: 0;
    z-index: -1;
    content: "";
    position: absolute;
    transform: rotate(90deg)
}

@media(max-width:991px) {
    .block-wraper .shape-bg-rotate {
        right: 30%;
        bottom: -20%
    }
}

@media(max-width:500px) {
    .block-wraper .shape-bg-rotate {
        display: none
    }
}

@media(max-width:991px) {
    .block-img {
        margin-top: 60px
    }
    .block-img img {
        max-width: 70%
    }
}

.latest-blog-wrap .block-content {
    margin-bottom: -30px
}

.latest-blog-item {
    z-index: 1;
    margin-top: 40px;
    position: relative;
    padding: 60px 65px;
    box-shadow: 0 12px 40px 0 rgba(0, 0, 0, .06)
}

@media(max-width:767px) {
    .latest-blog-item {
        padding: 40px 45px
    }
}

.latest-blog-item:before {
    top: 0;
    left: 0;
    z-index: -1;
    content: "";
    width: 100%;
    height: 100%;
    transition: .3s;
    position: absolute;
    background-color: #fff
}

.latest-blog-item a,
.latest-blog-item p {
    transition: .3s
}

.latest-blog-item .post-cat a {
    color: #444;
    font-size: 14px;
    position: relative;
    padding-right: 5px
}

.latest-blog-item .post-cat a:before {
    right: 0;
    content: ",";
    position: absolute
}

.latest-blog-item .post-cat a:last-child:before {
    display: none
}

.latest-blog-item .post-title {
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 28px !important
}

.latest-blog-item .post-title a {
    font-weight: 700;
    line-height: 28px !important
}

.latest-blog-item .read-more {
    color: #444;
    transition: .3s;
    margin-top: 25px;
    display: inline-block;
    text-transform: uppercase
}

.latest-blog-item .read-more i {
    margin-left: 10px
}

.latest-blog-item:hover:before {
    opacity: .85;
    background: #000
}

.latest-blog-item:hover a,
.latest-blog-item:hover p {
    color: #fff
}

.brand-wrap {
    background-color: #fff8ed
}

.brand-wrap .container {
    display: grid;
    grid-row-gap: 100px;
    justify-items: center;
    align-content: center;
    grid-template-columns: repeat(5, 1fr)
}

@media(max-width:991px) {
    .brand-wrap .container {
        grid-template-columns: repeat(3, 1fr)
    }
}

@media(max-width:767px) {
    .brand-wrap .container {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 60px
    }
}

@media(max-width:500px) {
    .brand-wrap .container {
        grid-template-columns: repeat(2, 1fr)
    }
}

.brand-wrap .single-brand-logo img {
    filter: brightness(0%)
}

.faq-wrap-contact-us {
    background-image: linear-gradient(150deg, #189bfb 0%, #000b7f 100%);
    overflow: hidden
}

.faq-wrap-contact-us::before {
    background: rgb(202, 202, 202);
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 103%;
    height: 21%;
    z-index: -1;
    transform: scale(1.6) rotate(-7deg)
}

.faq-wrap-contact-us .faq-sec-bg {
    position: absolute;
    bottom: 0;
    z-index: -1;
    background-size: contain;
    height: 100%;
    width: 100%;
    content: "";
    background-repeat: no-repeat;
    background-position: bottom
}

.faq-wrap-contact-us .faq-contact-shape .shape.faqs4 {
    top: 725px;
    left: 410px
}

.faq-wrap-contact-us .faq-contact-shape .shape.faqs2 {
    bottom: 520px;
    left: 310px
}

.faq-wrap-contact-us .faq-contact-shape .shape.faqs3 {
    right: 110px;
    bottom: 510px
}

.faq-wrap-contact-us .faq-contact-shape .shape.faqs1 {
    bottom: 430px;
    right: 430px
}

.faq-accordion {
    margin-top: 85px;
    min-height: 490px
}

@media(max-width:767px) {
    .faq-accordion {
        margin-top: 60px
    }
}

@media(max-width:991px) {
    .faq-accordion {
        margin-top: 70px
    }
}

.faq-accordion .accordion {
    color: #444
}

.faq-accordion .accordion .card-header {
    padding: 9.5px 28px;
    border-color: #e4e4ec;
    background-color: transparent
}

@media(max-width:480px) {
    .faq-accordion .accordion .card-header {
        padding: 9px 15px
    }
}

.faq-accordion .accordion .card-header a {
    display: block;
    color: #2e77ff;
    font-size: 19px;
    line-height: 50px;
    position: relative
}

.faq-accordion .accordion .card-header a:after {
    content: "";
    font-family: "font awesome 5 pro";
    position: absolute;
    right: 0
}

.faq-accordion .accordion .card-header a[aria-expanded=true]:after {
    content: ""
}

@media(max-width:767px) {
    .faq-accordion .accordion .card-header a {
        font-size: 16px;
        line-height: 30px
    }
}

.faq-accordion .accordion .card-body {
    padding-left: 28px
}

.faq-wraper {
    background-color: #fff8ed
}

.faq-wraper .faq-accordion {
    margin-top: 50px;
    min-height: auto
}

.faq-wraper .faq-content .card {
    border: none;
    margin-top: 30px
}

.faq-wraper .faq-content .card .card-header {
    background-color: #ffedd2;
    border: none
}

.faq-wraper .faq-content .card .card-header a {
    color: #04004d
}

.faq-wraper .faq-content .card .card-header a:after {
    content: ""
}

.faq-wraper .faq-content .card .card-header a[aria-expanded=true]:after {
    content: ""
}

.faq-wraper .faq-banner {
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    content: "";
    position: absolute;
    background-size: cover;
    background-position: right center
}

@media(max-width:1191px) {
    .faq-wraper .faq-banner {
        width: 30%;
        background-position: center
    }
}

@media(max-width:991px) {
    .contact-us {
        padding-top: 100px
    }
}

@media(max-width:767px) {
    .contact-us {
        padding-top: 80px
    }
}

.contact-us .section-title {
    padding: 0 53px
}

.contact-us .section-title p {
    margin-top: 16px;
    color: #1d1d1d !important;
    
}
.bck-red{
    background: #7c0000 !important;
}
.f-group{
    margin-bottom: 25px;
}

@media(max-width:767px) {
    .contact-us .section-title {
        padding: 0 20px
    }
}

@media(max-width:600px) {
    .contact-us .section-title {
        padding: 0
    }
}

.contact-us .contact-form form .single-input input,
.contact-us .contact-form form .single-input textarea {
    z-index: 1;
    width: 100%;
    height: 64px;
    color: #333;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    line-height: 64px;
    border-width: 1px;
    position: relative;
    border-radius: 5px;
    padding-left: 20px;
    border-style: solid;
    border-color: #538cd5;
    transition: all .3s ease-in-out
}

.contact-us .contact-form form .single-input input::placeholder,
.contact-us .contact-form form .single-input textarea::placeholder {
    color: #333
}

.contact-us .contact-form form .single-input textarea {
    min-height: 210px
}

.contact-us .contact-form form .submit-btn.theme-btn {
    font-size: 19px;
    min-width: 200px;
    margin-top: 20px;
    padding: 11.5px 40px;
    text-transform: capitalize;
    box-shadow: 0 5px 15px 0 rgba(1, 29, 80, .1)
}

@media(max-width:767px) {
    .contact-us .contact-form form .submit-btn.theme-btn {
        padding: 9px 25px;
        font-size: 16px
    }
}

.contact-us .contact-form form .form-btn span {
    color: #fff;
    margin-top: 30px;
    display: inline-block;
    line-height: 1
}

.footer_waves_wrap {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 125px;
    z-index: -1
}

@media(max-width:991px) {
    .footer_waves_wrap {
        height: 90px
    }
}

.footer_waves_wrap svg {
    position: absolute;
    z-index: 1;
    background-repeat: repeat-x
}

.footer_waves_wrap svg.wave_bot {
    bottom: -42px !important;
    left: -43px
}

.footer_waves_wrap svg.wave_mid {
    bottom: -15px;
    left: -38%
}

.footer_waves_wrap svg.wave_top {
    left: -15%
}

.footer-wrap {
    position: relative;
    padding: 60px 0;
    overflow: hidden
}

@media(max-width:767px) {
    .footer-wrap {
        text-align: center
    }
}

.footer-wrap .footer-info .footer-logo {
    overflow: auto;
    margin-right: 12px
}

.footer-wrap .footer-info .copyright-text {
    overflow: auto
}

.footer-wrap .footer-info .copyright-text p {
    margin-top: -5px;
    font-size: 14px;
    line-height: 24px
}

.footer-wrap .footer-menu ul li {
    display: inline-block
}

.footer-wrap .footer-menu ul li a {
    display: inline-block;
    color: #000000;
    transition: all .3s ease-in-out;
    font-size: 16px;
    line-height: 24px;
    margin-right: 35px
}

.footer-wrap .footer-menu ul li a:hover {
    color: #f4b930
}

@media(max-width:1220px) {
    .footer-wrap .footer-menu ul li a {
        margin-right: 25px
    }
}

.footer-wrap .footer-menu ul li:last-child a {
    margin-right: 0
}

.footer-wrap .social-profile-links a {
    border-width: 1px;
    border-color: #2e77ff;
    border-style: solid;
    border-radius: 50%;
    background-color: #fff;
    width: 46px;
    height: 46px;
    display: inline-block;
    line-height: 46px;
    text-align: center;
    transition: all .4s ease-in-out;
    margin-left: 3px
}

@media(max-width:1220px) {
    .footer-wrap .social-profile-links a {
        width: 34px;
        height: 34px;
        line-height: 34px;
        font-size: 14px
    }
}

.footer-wrap .social-profile-links a:hover {
    border-radius: 50%;
    background-color: #2e77ff;
    color: #fff
}

@media(max-width:991px) {
    .footer-wrap .social-profile-links {
        text-align: center;
        margin-top: 30px
    }
}

.footer_waves_wrap .wave_mid {
    animation: upanimate 5s infinite linear;
    background-repeat: repeat
}

.footer_waves_wrap .wave_top {
    animation: updown 4s infinite linear;
    background-repeat: repeat
}

.footer-five {
    background-color: #fff8ed
}

.footer-five .footer-top {
    padding: 80px 0 105px
}

@media(max-width:991px) {
    .footer-five .footer-top {
        padding: 40px 0 65px
    }
}

.footer-five .footer-top .single-footer-item {
    margin-top: 40px
}

.footer-five .footer-top .single-footer-item .widget-title {
    margin-bottom: 45px
}

@media(max-width:991px) {
    .footer-five .footer-top .single-footer-item .widget-title {
        margin-bottom: 30px
    }
}

.footer-five .footer-top .single-footer-item .widget-title h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500
}

.footer-five .footer-top .single-footer-item ul li a {
    color: #6d697d;
    transition: .3s
}

.footer-five .footer-top .single-footer-item ul li a:hover {
    color: #04004d
}

.footer-five .footer-top .fo-icon {
    float: left;
    overflow: auto;
    margin-right: 15px
}

.footer-five .footer-top .fo-info {
    overflow: auto
}

.footer-five .footer-top .widgets-footer-element {
    display: block;
    margin-bottom: 14px
}

.footer-five .footer-top .subscribe-form form {
    width: 100%;
    height: 54px;
    overflow: hidden;
    position: relative
}

.footer-five .footer-top .subscribe-form form input {
    width: 100%;
    border: none;
    height: 100%;
    line-height: 54px;
    padding-left: 22px;
    padding-right: 50px;
    position: relative
}

.footer-five .footer-top .subscribe-form form button {
    right: 0;
    color: #fff;
    border: none;
    height: 100%;
    padding: 0 17px;
    text-align: center;
    position: absolute;
    display: inline-block;
    background-color: #785fff
}

.footer-five .footer-bottom {
    padding-bottom: 20px
}

.footer-five .footer-bottom hr {
    border-color: #e0e0e0;
    padding-bottom: 20px
}

.footer-five .footer-bottom i {
    color: red
}

.footer-five .footer-bottom a {
    color: #04004d;
    text-transform: capitalize
}

.wave {
    overflow: hidden;
    width: 100%
}

.wave svg {
    position: absolute;
    left: -825px;
    bottom: -70px
}

.section-title h2 {
    margin-top: -12px
}

@media(max-width:500px) {
    .section-title h2 {
        margin-top: 0
    }
}

.section-title p {
    color: #666668;
    line-height: 33px;
    margin-top: 6px
}

.section-btn-link a {
    color: #2e77ff;
    transition: all .3s ease-in-out
}

.section-btn-link a:hover {
    color: #f4b930
}

.section-btn-link a:hover span {
    border-color: transparent
}

.section-btn-link a span {
    border-bottom: 2px solid #96bbff
}

.section-btn-link a i {
    margin-left: 22px
}

@media only screen and (max-width:991px) {
    .brand-carousel-wrapper {
        padding: 100px 0
    }
}

@media only screen and (max-width:767px) {
    p {
        font-size: 16px;
        line-height: 30px !important
    }
    h1 {
        font-size: 36px;
        line-height: 1.3
    }
    h2 {
        font-size: 28px;
        line-height: 1.3
    }
    h3 {
        font-size: 22px;
        line-height: 1.4
    }
    h4 {
        font-size: 19px;
        line-height: 1.4
    }
    .hero-animate .animate.animate1 {
        left: 15px
    }
    .hero-animate .animate.animate2 {
        left: 200px
    }
    .hero-animate .animate.animate3 {
        left: 380px
    }
    .brand-carousel-wrapper {
        padding: 80px 0
    }
    .call-to-action-wrap .cta-banner h2 {
        margin-top: -7px
    }
    .footer_waves_wrap {
        height: 80px
    }
    .footer_waves_wrap svg.wave_mid {
        bottom: -45px
    }
    .footer-wrap .footer-info .footer-logo {
        float: inherit;
        overflow: auto;
        margin-right: 0;
        margin-bottom: 15px
    }
    .footer-wrap .footer-info .copyright-text {
        overflow: auto;
        margin-bottom: 20px
    }
    .footer-wrap .social-profile-links {
        text-align: center;
        margin-top: 20px
    }
    .footer-wrap .social-profile-links a {
        margin: 0 4px
    }
}

@media only screen and (max-width:680px) {
    .call-to-action-wrap .cta-banner .cta-btn {
        padding: 10px
    }
}

@media only screen and (max-width:380px) {
    .call-to-action-wrap .cta-banner .cta-btn {
        padding: 5px
    }
}
.single-hero-slide .slide-bg{
    background-image: url('augment-realty.jpg');
}
.qstion-box{
    
}
.mrg-top-100{
    margin-top: 100px;
}
.qstion-box .form-control{
    background-color: white !important;
}
.mrg-top-100{
    margin-top: 100px !important;
}
.heroa1{
    color: #000;
}
body{
    background-color: white;
    background: white !important;
}